import styled from 'styled-components'

const TabContainer = styled.div`
  .tabs-contain {
    .items{
      margin-left: 28px;
    }
  }
  .margin-rigth-tab {
    margin-right: 20px;
  }
  .title {
    font-size: 24px;
    text-transform: uppercase;
    text-decoration: unset;
    &.active, &:hover{
      color: #4EF076;
      position: relative;
      img {
        position: absolute;
        bottom: -7px;
        left: 0;
        width: 109px;
      }
    }
  }
`

export default TabContainer
