/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CLOSE_BTN } from 'assets/images'
import { Modal } from 'shared'
import { PROFILE_MESSAGE } from 'i18n/constants'
import { Form } from 'antd'
import Button from 'shared/Button'
import { NUMBER } from 'apps/constants'
import { getOnlyDecimal } from 'utils/helper'
import InputPrizePool from 'shared/InputPrizePool'
import ModalPrizePoolStyled from './styled'

interface ModalPrizePoolProps {
  onSaveClick?: (value?: any) => void
  toggleIsModalOpen: (value?: boolean) => void
  onCloseButtonClick: () => void
  prizePool?: any[] | undefined
  maxHorse?: number | string
}

function ModalPrizePool({
  onCloseButtonClick,
  prizePool = [],
  maxHorse=NUMBER.TWELVE,
  onSaveClick = () => {}
}: ModalPrizePoolProps) {
  const { t } = useTranslation()
  const defaulPrizePool = useRef(JSON.parse(JSON.stringify(prizePool))).current
  const [prizes, setPrizes] = useState(defaulPrizePool)
  const [valuePrize1, setvaluePrize1] = useState(defaulPrizePool[NUMBER.ZERO]?.value)
  const [valuePrize2, setvaluePrize2] = useState(defaulPrizePool[NUMBER.ONE]?.value)
  const [valuePrize3, setvaluePrize3] = useState(defaulPrizePool[NUMBER.TWO]?.value)
  const [valuePrize4, setvaluePrize4] = useState(defaulPrizePool[NUMBER.THREE]?.value)
  const [valuePrize5, setvaluePrize5] = useState(defaulPrizePool[NUMBER.FOUR]?.value)
  const [valuePrize6, setvaluePrize6] = useState(defaulPrizePool[NUMBER.FIVE]?.value)
  const [valuePrize7, setvaluePrize7] = useState(defaulPrizePool[NUMBER.SIX]?.value)
  const [valuePrize8, setvaluePrize8] = useState(defaulPrizePool[NUMBER.SEVEN]?.value)
  const [valuePrize9, setvaluePrize9] = useState(defaulPrizePool[NUMBER.EIGHT]?.value)
  const [valuePrize10, setvaluePrize10] = useState(defaulPrizePool[NUMBER.NINE]?.value)
  const [valuePrize11, setvaluePrize11] = useState(defaulPrizePool[NUMBER.TEN]?.value)
  const [valuePrize12, setvaluePrize12] = useState(defaulPrizePool[NUMBER.ELEVEN]?.value)
  
  const handleSaveRacePrize = () => {
    onSaveClick(prizes)
    onCloseButtonClick()
  }

  const handleClearAll = () => {
    setvaluePrize1(NUMBER.ZERO)
    setvaluePrize2(NUMBER.ZERO)
    setvaluePrize3(NUMBER.ZERO)
    setvaluePrize4(NUMBER.ZERO)
    setvaluePrize5(NUMBER.ZERO)
    setvaluePrize6(NUMBER.ZERO)
    setvaluePrize7(NUMBER.ZERO)
    setvaluePrize8(NUMBER.ZERO)
    setvaluePrize9(NUMBER.ZERO)
    setvaluePrize10(NUMBER.ZERO)
    setvaluePrize11(NUMBER.ZERO)
    setvaluePrize12(NUMBER.ZERO)
    const obj: any = [...prizes]?.map(prize => {
      prize.value = NUMBER.ZERO
      return prize
    })
    setPrizes(obj)
  }

  const _handleKeyDownPrize = (e: any) => {
    if (['e', 'E', '+', '-', ','].includes(e.key) || [38, 40].includes(e.keyCode)) {
      return e.preventDefault()
    }

    if (e.key === 'Unidentified') {
      return
    }
  }

  const _handleChangePrize = (e: any, position: number) => {
    const num = getOnlyDecimal(e, NUMBER.NUM_2147483647)
    switch (position) {
      case NUMBER.ONE: {
        setvaluePrize1(num)
        break;
      }
      case NUMBER.TWO: {
        setvaluePrize2(num)
        break;
      }
      case NUMBER.THREE: {
        setvaluePrize3(num)
        break;
      }
      case NUMBER.FOUR: {
        setvaluePrize4(num)
        break;
      }
      case NUMBER.FIVE: {
        setvaluePrize5(num)
        break;
      }
      case NUMBER.SIX: {
        setvaluePrize6(num)
        break;
      }
      case NUMBER.SEVEN: {
        setvaluePrize7(num)
        break;
      }
      case NUMBER.EIGHT: {
        setvaluePrize8(num)
        break;
      }
      case NUMBER.NINE: {
        setvaluePrize9(num)
        break;
      }
      case NUMBER.TEN: {
        setvaluePrize10(num)
        break;
      }
      case NUMBER.ELEVEN: {
        setvaluePrize11(num)
        break;
      }
      case NUMBER.TWELVE: {
        setvaluePrize12(num)
        break;
      }
      default:
        break;
    }

    const arrPrizes = prizes ?? []
    const obj: any = [...prizes]?.find(o => o.position === position);
    if (obj) obj.value = num || NUMBER.ZERO
    setPrizes(arrPrizes)
  }

  const renderContent = () => {
    return (
      <div className='prize-container'>
        <div className='color-DEDEDE'>
          {/* prize1 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className='title-label'>
                {t(`${PROFILE_MESSAGE}.input_prize1`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize1`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.ONE)}
                    value={valuePrize1?.toString()}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize2 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className='title-label'>
                {t(`${PROFILE_MESSAGE}.input_prize2`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize2`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.TWO)}
                    value={valuePrize2?.toString()}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize3 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className={`title-label ${+maxHorse < NUMBER.THREE ? 'disabled_text' : ''}`}>
                {t(`${PROFILE_MESSAGE}.input_prize3`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize3`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.THREE)}
                    value={valuePrize3?.toString()}
                    disabled={+maxHorse < NUMBER.THREE}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize4 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className={`title-label ${+maxHorse < NUMBER.FOUR ? 'disabled_text' : ''}`}>
                {t(`${PROFILE_MESSAGE}.input_prize4`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize4`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.FOUR)}
                    value={valuePrize4?.toString()}
                    disabled={+maxHorse < NUMBER.FOUR}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize5 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className={`title-label ${+maxHorse < NUMBER.FIVE ? 'disabled_text' : ''}`}>
                {t(`${PROFILE_MESSAGE}.input_prize5`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize5`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.FIVE)}
                    value={valuePrize5?.toString()}
                    disabled={+maxHorse < NUMBER.FIVE}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize6 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className={`title-label ${+maxHorse < NUMBER.SIX ? 'disabled_text' : ''}`}>
                {t(`${PROFILE_MESSAGE}.input_prize6`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize6`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.SIX)}
                    value={valuePrize6?.toString()}
                    disabled={+maxHorse < NUMBER.SIX}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize7 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className={`title-label ${+maxHorse < NUMBER.SEVEN ? 'disabled_text' : ''}`}>
                {t(`${PROFILE_MESSAGE}.input_prize7`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize7`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.SEVEN)}
                    value={valuePrize7?.toString()}
                    disabled={+maxHorse < NUMBER.SEVEN}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize8 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className={`title-label ${+maxHorse < NUMBER.EIGHT ? 'disabled_text' : ''}`}>
                {t(`${PROFILE_MESSAGE}.input_prize8`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize2`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.EIGHT)}
                    value={valuePrize8?.toString()}
                    disabled={+maxHorse < NUMBER.EIGHT}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize9 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className={`title-label ${+maxHorse < NUMBER.NINE ? 'disabled_text' : ''}`}>
                {t(`${PROFILE_MESSAGE}.input_prize9`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize9`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.NINE)}
                    value={valuePrize9?.toString()}
                    disabled={+maxHorse < NUMBER.NINE}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize10 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className={`title-label ${+maxHorse < NUMBER.TEN ? 'disabled_text' : ''}`}>
                {t(`${PROFILE_MESSAGE}.input_prize10`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize10`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.TEN)}
                    value={valuePrize10?.toString()}
                    disabled={+maxHorse < NUMBER.TEN}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize11 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className={`title-label ${+maxHorse < NUMBER.ELEVEN ? 'disabled_text' : ''}`}>
                {t(`${PROFILE_MESSAGE}.input_prize11`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize11`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.ELEVEN)}
                    value={valuePrize11?.toString()}
                    disabled={+maxHorse < NUMBER.ELEVEN}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* prize12 */}
          <div className='col-12'>
            <div className='box-prize'>
              <div className={`title-label ${+maxHorse < NUMBER.TWELVE ? 'disabled_text' : ''}`}>
                {t(`${PROFILE_MESSAGE}.input_prize12`)}
              </div>
              <div className='input-prize'>
                <Form.Item
                  className='input-box'
                  shouldUpdate
                  rules={[
                    { max: 16, message: t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`) },
                  ]}
                >
                  <InputPrizePool
                    name={`prize12`}
                    maxLength={16}
                    type='text'
                    onKeyDown={_handleKeyDownPrize}
                    onChange={e => _handleChangePrize(e, NUMBER.TWELVE)}
                    value={valuePrize12?.toString()}
                    disabled={+maxHorse < NUMBER.TWELVE}
                    placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_prize`)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* Button save */}
          <div className='row'>
            <div className='btn-create'>
              <Form.Item>
                <Button
                  buttonName={
                    <div>
                      {t(`${PROFILE_MESSAGE}.btn_clear_all`)}
                    </div>
                  }
                  onClickButton={handleClearAll}
                  width={147}
                  type="btnSelectAll"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  buttonName={
                    <div>
                      {t(`${PROFILE_MESSAGE}.btn_save`)}
                    </div>
                  }
                  onClickButton={handleSaveRacePrize}
                  width={147}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal>
      <ModalPrizePoolStyled>
        <div className='user-create_race-modal'>
          <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className={`title-create-race-container line-condition`}>
            <div className={`color-primary`}>
              {t(`${PROFILE_MESSAGE}.input_prize_pool`)}
            </div>
          </div>
          <div className={`create-race-container`}>
            {renderContent()}
          </div>
        </div>
      </ModalPrizePoolStyled>
    </Modal>
  )
}

export default ModalPrizePool
