/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd';
import { BTN_DETAIL_RACE, BTN_DETAIL_RACE_WARNING } from 'assets/images';
import ButtonStyled from './styled';

interface ButtonProps {
  onClickButton: () => void
  buttonName: string | React.ReactNode
  disabled?: boolean
  width?: number
  btnCancel?: boolean,
  isLoading?: boolean
  height?: number
  classNames?: any
  checkError?: boolean
}

function ButtonDetail({
  onClickButton,
  buttonName,
  disabled,
  width = 215,
  isLoading,
  height = 50,
  classNames,
  checkError = true
}: ButtonProps) {

  const handleClick = () => {
    if (disabled || isLoading) return
    onClickButton()
  }

  return (
    <ButtonStyled className={classNames} >
      <div className={`btn-container-${disabled || isLoading ? 'disable' : ''}`} onClick={handleClick} >
        <img src={checkError ? BTN_DETAIL_RACE : BTN_DETAIL_RACE_WARNING} alt='' className='background-btn' width={width} height={height} />
        <span className='btn-name'>{isLoading && <Spin className='mt-2 mr-3' />}{buttonName}</span>
      </div>
    </ButtonStyled>
  )
}

export default ButtonDetail
