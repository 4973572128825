/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getFeeToCreateRace, getListUserCreateRace, getRaceCreationLimit, postUserCancelRace, postUserCreateRace } from "./action";

interface initialStateType {
  loadingUserCreateRace: boolean
  loadingListRace: boolean
  loadingFee: boolean
  loadingLimit: boolean
  userCreateRace: any
  loadingCancelUserCreateRace: boolean
  userCreateRaceSuccess: string
  errorUserCreateRace: string
  errorListRace: string
  errorFee: string
  errorLimit: string
  errorCancelUserCreateRace: string
  limit: any
  fee: any
  cancelUserCreateRace: any
  dataInput?: any
}

const initialState: initialStateType = {
  loadingUserCreateRace: false,
  loadingListRace: false,
  loadingFee: false,
  loadingLimit: false,
  loadingCancelUserCreateRace: false,
  userCreateRace: {},
  userCreateRaceSuccess: "",
  errorUserCreateRace: "",
  errorListRace: "",
  errorFee: "",
  errorLimit: "",
  errorCancelUserCreateRace: "",
  limit: "",
  fee: "",
  cancelUserCreateRace: "",
  dataInput: {}
}

export const userCreateRaceSlice = createSlice({
  name: 'userCreateRace',
  initialState,
  reducers: {
    saveDataInput(state, action: PayloadAction<any>) {
      state.dataInput = {
        dataInput: {...action.payload}
      }
    },
    setNullUserCreateRaceSuccess(state) {
      state.userCreateRaceSuccess = ""
    },
    setNullErrorUserCreateRace(state) {
      state.errorUserCreateRace = ""
    },
  },
  extraReducers: {
    //get list userCreateRace
    [getListUserCreateRace.pending.type]: (state) => {
      state.loadingListRace = true
      state.errorListRace = ""
      state.userCreateRace = ""
    },

    [getListUserCreateRace.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingListRace = false;
      state.errorListRace = "";
      state.userCreateRace = action.payload;
    },
    
    [getListUserCreateRace.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingListRace = false;
      state.errorListRace = action.payload;
    },

    // post userCreateRace
    [postUserCreateRace.pending.type]: (state) => {
      state.loadingUserCreateRace = true
      state.errorUserCreateRace = "";
      state.userCreateRaceSuccess=""
    },

    [postUserCreateRace.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingUserCreateRace = false;
      // state.userCreateRace = action.payload;
      if (action.payload?.errors?.length) {
        state.errorUserCreateRace = action.payload?.errors[0]?.message || ""
        return
      }
      if (action.payload?.code !== 200) {
        state.errorUserCreateRace = action.payload?.message
        state.userCreateRaceSuccess=""
      } else {
        state.errorUserCreateRace = ""
        state.userCreateRaceSuccess="You have created race successfully"
      }
    },
    
    [postUserCreateRace.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingUserCreateRace = false;
      state.errorUserCreateRace = action.payload;
      state.userCreateRaceSuccess = ""
    },

    //get limit
    [getRaceCreationLimit.pending.type]: (state) => {
      state.loadingLimit = true
      state.errorLimit = "";
    },

    [getRaceCreationLimit.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingLimit = false;
      state.errorLimit = "";
      state.limit = action.payload;
    },
    
    [getRaceCreationLimit.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingLimit = false;
      state.errorLimit = action.payload;
    },

    //get fee
    [getFeeToCreateRace.pending.type]: (state) => {
      state.loadingFee = true
      state.errorFee = "";
    },

    [getFeeToCreateRace.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingFee = false;
      state.errorFee = "";
      state.fee = action.payload;
    },
    
    [getFeeToCreateRace.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingFee = false;
      state.errorFee = action.payload;
    },

    // post postUserCancelRace
    [postUserCancelRace.pending.type]: (state) => {
      state.loadingCancelUserCreateRace = true
      state.errorCancelUserCreateRace = "";
    },

    [postUserCancelRace.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.cancelUserCreateRace = action.payload;
      if (action.payload?.code !== 200) {
        state.errorCancelUserCreateRace = "";
      } else {
        state.errorCancelUserCreateRace = action.payload?.message;
      }
      state.loadingCancelUserCreateRace = false;
    },
    
    [postUserCancelRace.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingCancelUserCreateRace = false;
      state.errorCancelUserCreateRace = action.payload;
    },
  },
})

export const { saveDataInput } = userCreateRaceSlice.actions
export const { setNullUserCreateRaceSuccess } = userCreateRaceSlice.actions
export const { setNullErrorUserCreateRace } = userCreateRaceSlice.actions

export default userCreateRaceSlice.reducer
