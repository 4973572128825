/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CLOSE_BTN, TWO_LINE } from 'assets/images'
import { Modal } from 'shared'
import { PROFILE_MESSAGE } from 'i18n/constants'
import ModalUserCreateRaceStyled from './styled'
import { Form } from 'antd'
import Button from 'shared/Button'
import SelectCustomCreateRace from 'shared/SelectCustomCreateRace'
import { NUMBER, REGEX_FIRST_SPACE, STRING, STRING_USER_CREATE_RACE } from 'apps/constants'
import { getClassRace, getDistanceRace, getOnlyDecimal, getOnlyNumber, handleAsyncRequest, removeEmojis } from 'utils/helper'
import ButtonDetail from 'shared/ButtonDetail'
import { DistanceFilters, DistanceOption, RaceClassNumber, RaceFieldType, RegionFilters, RegionOption, TimeTypeFilters, TimeTypeOption } from 'models'
import { ClassFilters, TypeFilters } from 'features/Race/pages/Open'
import { useAppDispatch, useToggle } from 'hooks'
import ModalRaceCoditions from '../ModalRaceCoditions'
import ModalPrizePool from '../ModalPrizePool'
import { postUserCreateRace } from 'apps/redux/userCreateRace/action'
import InputPrizePool from 'shared/InputPrizePool'
import ModalConfirmUserCreateRace from '../ModalConfirmUserCreateRace'
import userApi from 'apis/userApi'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import { saveDataInput } from 'apps/redux/userCreateRace/userCreateRace.slice'
import { dataInputSelector } from 'apps/redux/userCreateRace/selector'
import { useSelector } from 'react-redux'

interface ModalUserCreateRaceProps {
  toggleIsModalOpen: (value?: boolean) => void
  onCloseButtonClick: () => void
  onConfirmCreateRaceClick: () => void 
}

const keyValid = /[`!@#$%^&*~()_+\-=\[\]{};':"\\|,.<>\/?]+/
const keyValidPass = /[`!@#$%^&*~()_+\-=\[\]{};':"\\|,.<>\/? ]+/
const regexDay = /^\d+$/
const regex = new RegExp('^[a-zA-Z0-9 ]{0,50}$')
const allowedRegex = new RegExp('^[ｧ-ﾝﾞﾟァ-ン　ーぁ-んa-zA-Z0-9Ａ-Ｚａ-ｚ０-９ ]+$')

const defaultFieldFilters: TypeFilters = [
  {
    name: RaceFieldType.Turf,
    isActive: false
  },
  {
    name: RaceFieldType.Dirt,
    isActive: false
  }
]
const defaultClassFilters: ClassFilters = [
  {
    name: RaceClassNumber.Class1,
    isActive: false
  },
  {
    name: RaceClassNumber.Class2,
    isActive: false
  },
  {
    name: RaceClassNumber.Class3,
    isActive: false
  },
  {
    name: RaceClassNumber.Class4,
    isActive: false
  },
  {
    name: RaceClassNumber.Class5,
    isActive: false
  },
  {
    name: RaceClassNumber.Class6,
    isActive: false
  },
  {
    name: RaceClassNumber.Class7,
    isActive: false
  },
  {
    name: RaceClassNumber.ClassFreeStyle,
    isActive: false
  }
]
const defaultTimeTypeFilters: TimeTypeFilters = [
  {
    name: TimeTypeOption.DEFAULD,
    isActive: false
  },
  {
    name: TimeTypeOption.STRART_IN,
    isActive: false
  }
]
const defaultRegionFilters: RegionFilters = [
  {
    name: RegionOption.TOKYO,
    isActive: false
  },
  {
    name: RegionOption.SEOUL,
    isActive: false
  }
]
const defaultDistanceFilters: DistanceFilters = [
  {
    name: DistanceOption.DISTANCE_1000,
    isActive: true
  },
  {
    name: DistanceOption.DISTANCE_1200,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_1400,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_1600,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_1800,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_2000,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_2200,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_2400,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_2600,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_2800,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_3000,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_3200,
    isActive: false
  },
]
const defaultMinMaxHorseFilters = [
  {
    name: STRING.STR_2
  },
  {
    name: STRING.STR_3
  },
  {
    name: STRING.STR_4
  },
  {
    name: STRING.STR_5
  },
  {
    name: STRING.STR_6
  },
  {
    name: STRING.STR_7
  },
  {
    name: STRING.STR_8
  },
  {
    name: STRING.STR_9
  },
  {
    name: STRING.STR_10
  },
  {
    name: STRING.STR_11
  },
  {
    name: STRING.STR_12
  },
]
const defaultConditions = {
  armor: true,
  attribute: true,
  characteristic: true,
  distance: true,
  doping: true,
  field: true,
  skin: true,
  weather: true
}

const defaultPrizePool = [
  {
    position: NUMBER.ONE,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.TWO,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.THREE,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.FOUR,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.FIVE,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.SIX,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.SEVEN,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.EIGHT,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.NINE,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.TEN,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.ELEVEN,
    value: NUMBER.ZERO
  },
  {
    position: NUMBER.TWELVE,
    value: NUMBER.ZERO
  }
]

function ModalUserCreateRace({
  onCloseButtonClick,
  onConfirmCreateRaceClick
}: ModalUserCreateRaceProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const dataSaveSelector = useSelector(dataInputSelector)
  const [form] = Form.useForm()
  const [classSelect, setClassSelect] = useState(RaceClassNumber.Class1)
  const [regionSelect, setRegionSelect] = useState(RegionOption.TOKYO)
  const [fieldSelect, setFieldSelect] = useState(RaceFieldType.Turf)
  const [distanceSelect, setDistanceSelect] = useState(DistanceOption.DISTANCE_1000)
  const [timeTypeSelect, setTimeTypeSelect] = useState(TimeTypeOption.DEFAULD)
  const [minHorseSelect, setMinHorseSelect] = useState(STRING.STR_12)
  const [maxHorseSelect, setMaxHorseSelect] = useState<any>(STRING.STR_12)
  const [defaultMaxHorseFilters, setDefaultMaxHorseFilters] = useState(defaultMinMaxHorseFilters)
  const [conditionObj, setConditionObj] = useState(defaultConditions)
  const [isModalRaceCoditions, toggleIsModalRaceCoditions] = useToggle(false)
  const [isModalPrizePool, toggleIsModalPrizePool] = useToggle(false)
  const [prizePool, setPrizePool] = useState(defaultPrizePool)
  const [sumPrize, setSumPrize] = useState(NUMBER.ZERO)
  const [multiEntry, setMultiEntry] = useState(NUMBER.ZERO)
  const [errorRaceCloseAt, setErrorRaceCloseAt] = useState("")
  const [checkErrorBtnPrize, setCheckErrorBtnPrize] = useState(true)
  const [confirmUserCreateRace, toggleConfirmUserCreateRace] = useToggle(false)
  const [requestData, setRequestData] = useState<any>("")
  const [checkTypeFee, setCheckTypeFee] = useState<any>("free")

  useEffect(() => {
    setSumPrize(getSumPrize(dataSaveSelector?.dataInput?.prize || prizePool))
    setMultiEntry(getMultiEntry(minHorseSelect))
    form.setFieldsValue({
      raceName: dataSaveSelector?.dataInput?.raceName || '',
      password: dataSaveSelector?.dataInput?.password || '',
      entryFee: dataSaveSelector?.dataInput?.entryFee || '',
      dayStartIn: dataSaveSelector?.dataInput?.dayStartIn || STRING.STR_0,
      hourStartIn: dataSaveSelector?.dataInput?.hourStartIn || STRING.STR_0,
      minuteStartIn: dataSaveSelector?.dataInput?.minuteStartIn || STRING.STR_0
    })
    setRegionSelect(dataSaveSelector?.dataInput?.course || RegionOption.TOKYO)
    setDistanceSelect(dataSaveSelector?.dataInput?.distance || DistanceOption.DISTANCE_1000)
    setFieldSelect(dataSaveSelector?.dataInput?.fieldType || RaceFieldType.Turf)
    setMaxHorseSelect(dataSaveSelector?.dataInput?.maxGate || STRING.STR_12)
    setMinHorseSelect(dataSaveSelector?.dataInput?.minGate || STRING.STR_12)
    setPrizePool(dataSaveSelector?.dataInput?.prize || defaultPrizePool)
    setConditionObj(dataSaveSelector?.dataInput?.conditionsRequest || defaultConditions)
    setClassSelect(dataSaveSelector?.dataInput?.raceClass || RaceClassNumber.Class1)
    setTimeTypeSelect(dataSaveSelector?.dataInput?.startType || TimeTypeOption.DEFAULD)
  }, [])  

  useEffect(() => {
    setMultiEntry(getMultiEntry(minHorseSelect))
  }, [minHorseSelect])

  useEffect(() => {
    const entryFee = form.getFieldValue('entryFee')
    if (entryFee && sumPrize) {
      setCheckTypeFee("paid")
    } else if (!entryFee && sumPrize) {
      setCheckTypeFee("free_prize")
    } else {
      setCheckTypeFee("free")
    }

    if (+entryFee) {
      if (!sumPrize || sumPrize > multiEntry) {
        setCheckErrorBtnPrize(false)
      } else {
        setCheckErrorBtnPrize(true)
      }
      form.validateFields(['entryFee']).catch(() => {
        if (!sumPrize) {
          form.setFields([{ name: 'entryFee', errors: [t(`${PROFILE_MESSAGE}.message.validate_prize_empty`)] }])
        } else if (sumPrize > multiEntry) {
          form.setFields([{ name: 'entryFee', errors: [t(`${PROFILE_MESSAGE}.message.validate_prize_greater_fee`)] }])
        } else {
          form.setFields([{ name: 'entryFee', errors: [] }])
        }
      })
    } else if (!entryFee && +multiEntry === NUMBER.ZERO) {
      setCheckErrorBtnPrize(true)
    }
  }, [sumPrize, multiEntry, form.getFieldValue('entryFee')])

  const handleClose = () => {
    const requestData = {
      raceName: form.getFieldValue("raceName") || '',
      password: form.getFieldValue("password") || '',
      entryFee: form.getFieldValue("entryFee") || '',
      dayStartIn: form.getFieldValue("dayStartIn") || STRING.STR_0,
      hourStartIn: form.getFieldValue("hourStartIn") || STRING.STR_0,
      minuteStartIn: form.getFieldValue("minuteStartIn") || STRING.STR_0,
      course: regionSelect || RegionOption.TOKYO,
      distance: distanceSelect || DistanceOption.DISTANCE_1000,
      fieldType: fieldSelect || RaceFieldType.Turf,
      maxGate: +maxHorseSelect || NUMBER.ZERO,
      minGate: +minHorseSelect || NUMBER.ZERO,
      prize: prizePool,
      conditionsRequest: conditionObj,
      raceClass: classSelect || RaceClassNumber.Class1,
      startType: timeTypeSelect || TimeTypeOption.DEFAULD
    }
    dispatch(saveDataInput(requestData))
    form.resetFields()
    onCloseButtonClick()
  }

  const getMultiEntry = (minHorseSelect: any) => {
    return (Number(form.getFieldValue("entryFee") || 0) * Number(minHorseSelect || 12) * 1000000) / 1000000
  }

  const handleSubmit = async (value: any) => {
    let finishedAfter = NUMBER.ZERO
    const dayStartIn = Number(value.dayStartIn) || NUMBER.ZERO
    const hourStartIn = Number(value.hourStartIn) || NUMBER.ZERO
    const minuteStartIn = Number(value.minuteStartIn) || NUMBER.ZERO
    if (!dayStartIn && !hourStartIn && !minuteStartIn && timeTypeSelect !== "Default") {
      setErrorRaceCloseAt(t(`${PROFILE_MESSAGE}.message.validate_close_at_empty`))
      return
    } else {
      setErrorRaceCloseAt("")
    }
    if (dayStartIn || hourStartIn || minuteStartIn) {
      finishedAfter = dayStartIn * 24 * 60 + hourStartIn * 60 + minuteStartIn;
    }

    const request: any = {
      conditions_request: conditionObj,
      course: regionSelect?.toUpperCase(),
      distance: getDistanceRace(distanceSelect) || NUMBER.ZERO,
      entry_fee: +value.entryFee || NUMBER.ZERO,
      field_type: fieldSelect,
      finished_after: finishedAfter,
      max_gate: +maxHorseSelect || NUMBER.ZERO,
      min_gate: +minHorseSelect || NUMBER.ZERO,
      name: (value.raceName)?.trim(),
      prize: prizePool,
      race_class: getClassRace(classSelect),
      start_type: timeTypeSelect === "Default" ? "DEFAULT" : "START_AFTER_OPEN"
    }
    if (value.password) {
      request.password = (value.password)?.trim()
    }
    setRequestData(request)

    toggleConfirmUserCreateRace(true)
  }

  const handleConfirmClick = async () => {
    // dispatch user create race
    await dispatch(postUserCreateRace(requestData))
    toggleConfirmUserCreateRace(false)
    onCloseButtonClick()
    onConfirmCreateRaceClick()
    // call back coin item in header
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return
    dispatch(setCoinUser(resultCoinUser.data))
    dispatch(saveDataInput({}))
  }

  const _handleKeyDown = (e: any) => {
    // if (constantEmojis.test(e.key)) {
    //   e.preventDefault()
    // }
  
    if (keyValid.test(e.key)) {
      return e.preventDefault()
    }

    return
  }

  const _handleKeyDownPass = (e: any) => {  
    if (keyValidPass.test(e.key)) {
      return e.preventDefault()
    }
    return
  }

  const _handleRaceNameChange = async (e: any) => {
    const newValue = await removeEmojis(e)
    form.setFieldValue('raceName', newValue)
  }

  const _handlePasswordChange = async (e: any) => {
    const password = form.getFieldValue('password')
    
    if (!password) {
      form.validateFields(['password']).catch(() => {
        form.setFields([{ name: 'password', errors: [] }])
      })
    }
    const newValue = await removeEmojis(e)
    form.setFieldValue('password', newValue)
  }

  const _handleSelected = (data: any, type: string) => {
    switch (type) {
      case STRING_USER_CREATE_RACE.CLASS: {
        setClassSelect(() => data.name)
        break;
      }
      case STRING_USER_CREATE_RACE.REGION: {
        setRegionSelect(() => data.name)
        break;
      }
      case STRING_USER_CREATE_RACE.FIELD: {
        setFieldSelect(() => data.name)
        break;
      }
      case STRING_USER_CREATE_RACE.DISTANCE: {
        setDistanceSelect(() => data.name)
        break;
      }
      case STRING_USER_CREATE_RACE.TIME_TYPE: {
        setErrorRaceCloseAt("")
        setTimeTypeSelect(() => data.name)
        if (data.name === 'Default') {
          setMinHorseSelect(STRING.STR_12)
          setMaxHorseSelect(STRING.STR_12)
          setDefaultMaxHorseFilters(defaultMinMaxHorseFilters)
        } else {
          setMinHorseSelect(STRING.STR_2)
          setMaxHorseSelect(STRING.STR_12)
          setDefaultMaxHorseFilters(defaultMinMaxHorseFilters?.filter((item: any) => +item.name >= NUMBER.TWO))
        }
        break;
      }
      case STRING_USER_CREATE_RACE.MIN_HORSE: {
        setMinHorseSelect(() => data.name)
        if (timeTypeSelect === 'Default') {
          setMaxHorseSelect(() => data.name)
          setDefaultMaxHorseFilters(defaultMinMaxHorseFilters)
        } else {
          if (+maxHorseSelect < +data.name && +data.name < NUMBER.TWELVE) {
            setMaxHorseSelect((+data.name + 1)?.toString())
          }
          setDefaultMaxHorseFilters(defaultMinMaxHorseFilters?.filter((item: any) => +item.name >= +data.name))
        }
        break;
      }      
      case STRING_USER_CREATE_RACE.MAX_HORSE: {
        setMaxHorseSelect(() => data.name)
        if (timeTypeSelect === 'Default') {
          setMinHorseSelect(() => data.name)
        }
        break;
      }
      default:
        break;
    }
  }

  const _handleKeyDownEntryFee = (e: any) => {
    if (['e', 'E', '+', '-', ','].includes(e.key) || [38, 40].includes(e.keyCode)) {
      return e.preventDefault()
    }

    if (e.key === 'Unidentified') {
      return
    }
  }

  const _handleKeyDownDay = (e: any) => {
    if (['e', 'E', '+', '-', '.', ','].includes(e.key) || [38, 40].includes(e.keyCode)) {
      return e.preventDefault()
    }

    if (!regexDay.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight') {
      return e.preventDefault()
    }

    if (e.key === 'Unidentified') {
      return
    }
  }

  const _handleChangeEntryFee = (e: any) => {
    const num = getOnlyDecimal(e, NUMBER.NUM_2147483647)
    form.setFieldValue('entryFee', num)
    setMultiEntry(getMultiEntry(minHorseSelect))
  }

  const _handleChangeDay = (e: any, type: string) => {
    let num = getOnlyNumber(e)
    switch (type) {
      case STRING_USER_CREATE_RACE.DAY: {
        if (+num > 365) {
          num = 365
        }
        form.setFieldValue('dayStartIn', num)
        break;
      }
      case STRING_USER_CREATE_RACE.HOUR: {
        if (+num > 23) {
          num = 23
        }
        form.setFieldValue('hourStartIn', num)
        break;
      }
      case STRING_USER_CREATE_RACE.MINUTE: {
        if (+num > 59) {
          num = 59
        }
        form.setFieldValue('minuteStartIn', num)
        break;
      } 
    
      default:
        break;
    }
    if (!Number(form.getFieldValue("dayStartIn") || 0) && !Number(form.getFieldValue("hourStartIn") || 0) && !Number(form.getFieldValue("minuteStartIn") || 0)) {
      setErrorRaceCloseAt(t(`${PROFILE_MESSAGE}.message.validate_close_at_empty`))
    } else {
      setErrorRaceCloseAt("")
    }
  }

  const _handleClickBTNPrizePool = () => {
    toggleIsModalPrizePool(true)
  }

  const _handleClickBTNRaceCondition = () => {
    toggleIsModalRaceCoditions(true)
  }

  const handleSaveClickRaceCodition = (data: any) => {
    setConditionObj(data)
  }

  const getSumPrize = (data: any) => {
    let sum = 0
    data?.map((item: any) => {
      sum = sum + Number(item.value || 0) * 1000000
    })
    return sum / 1000000
  }

  const handleSaveClickPrize = (data: any) => {
    setPrizePool(data)
    setSumPrize(getSumPrize(data))
    setMultiEntry(getMultiEntry(minHorseSelect))
  }

  const renderContent = () => {
    return (
      <>
        <Form form={form} name='basic' onFinish={handleSubmit} autoComplete="off">
          {(values, instance) => {
            const raceNameError = instance.getFieldError('raceName')
            const passwordError = instance.getFieldError('password')
            const entryFeeError = instance.getFieldError('entryFee')
            const isError = raceNameError?.length > 0 || passwordError?.length > 0 || errorRaceCloseAt?.length > 0 || entryFeeError?.length > 0
            
            return (
              <div className='color-DEDEDE'>
                <div className='row height-85'>
                  {/* Race name */}
                  <div className='col-6'>
                    <div>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_race_name`)}
                        <span className='request__red'>{t(`${PROFILE_MESSAGE}.message.validate_request`)}</span>
                      </div>
                      <div>
                        <Form.Item
                          name='raceName'
                          className='input-box'
                          shouldUpdate
                          rules={[
                            { required: true, message: t(`${PROFILE_MESSAGE}.message.validate_req_race_name`) },
                            {
                              validator: async (_, value) => {
                                if (value && value?.length > 25) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.validate_max_race_name`)))
                                }
                                if (value && (value.includes('  ') || value.includes('　　'))) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.text_error_2_space`)))
                                }
                                if (value && REGEX_FIRST_SPACE.test(value)) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.text_error_space_race_name`)))
                                }

                                const newValue = await removeEmojis(value || '')
                                if (value && !allowedRegex.test(newValue)) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.validate_regex_race_name`)))
                                }
                                return Promise.resolve()
                              }
                            }
                          ]}
                        >
                          <InputPrizePool
                            name='raceName'
                            maxLength={25}
                            type='text'
                            onKeyDown={_handleKeyDown}
                            onChange={_handleRaceNameChange}
                            value={values.raceName}
                            placeholder={t(`${PROFILE_MESSAGE}.placeholder.race_name`)}
                            heigth={40}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  {/* Class */}
                  <div className='col-6'>
                    <div className='box-select'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_class`)}
                      </div>
                      <SelectCustomCreateRace
                        dataSelect={defaultClassFilters}
                        nameSelect={classSelect}
                        onSelected={(val) => _handleSelected(val, STRING_USER_CREATE_RACE.CLASS)}
                      />
                    </div>
                  </div>
                </div>

                <div className='row height-85'>
                  {/* Region */}
                  <div className='col-6'>
                    <div className='box-select'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_race_course`)}
                      </div>
                      <SelectCustomCreateRace
                        dataSelect={defaultRegionFilters}
                        nameSelect={regionSelect}
                        onSelected={(val) => _handleSelected(val, STRING_USER_CREATE_RACE.REGION)}
                      />
                    </div>
                  </div>

                  {/* Field */}
                  <div className='col-6'>
                    <div className='box-select'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_field`)}
                      </div>
                      <SelectCustomCreateRace
                        dataSelect={defaultFieldFilters}
                        nameSelect={fieldSelect}
                        onSelected={(val) => _handleSelected(val, STRING_USER_CREATE_RACE.FIELD)}
                      />
                    </div>
                  </div>
                </div>

                <div className='row height-85'>
                  {/* Password */}
                  <div className='col-6'>
                    <div className='box-select'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_password`)}
                      </div>
                      <div>
                        <Form.Item
                          name='password'
                          className='input-box'
                          shouldUpdate
                          rules={[
                            {
                              validator: async (_, value) => {
                                const newValue = await removeEmojis(value || '')
                                if (value && value.includes(' ')) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.validate_space_password`)))
                                }
                                if (value && newValue?.length > 0 && newValue?.length < 5) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.validate_pasword_min_length`)))
                                }
                                if (value && newValue?.length > 10) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.validate_pasword_max_length`)))
                                }
                                if (value && !regex.test(newValue)) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.validate_regex_password`)))
                                }
                                return Promise.resolve()
                              }
                            }
                          ]}
                        >
                          <InputPrizePool
                            name='password'
                            maxLength={10}
                            minLength={5}
                            type='text'
                            onKeyDown={_handleKeyDownPass}
                            onChange={_handlePasswordChange}
                            value={values.password}
                            placeholder={t(`${PROFILE_MESSAGE}.placeholder.password`)}
                            heigth={40}
                            typePass={true}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  {/* Distance */}
                  <div className='col-6'>
                    <div className='box-select'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_distance`)}
                      </div>
                      <SelectCustomCreateRace
                        dataSelect={defaultDistanceFilters}
                        nameSelect={distanceSelect}
                        onSelected={(val) => _handleSelected(val, STRING_USER_CREATE_RACE.DISTANCE)}
                      />
                    </div>
                  </div>
                </div>

                <div className='row height-85'>
                  {/* Time Type */}
                  <div className='col-6'>
                    <div className='box-select'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_time_type`)}
                      </div>
                      <SelectCustomCreateRace
                        dataSelect={defaultTimeTypeFilters}
                        nameSelect={timeTypeSelect}
                        onSelected={(val) => _handleSelected(val, STRING_USER_CREATE_RACE.TIME_TYPE)}
                      />
                    </div>
                  </div>

                  {/* Min Horse */}
                  <div className='col-6'>
                    <div className='box-select'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_min_horse`)}
                      </div>
                      <SelectCustomCreateRace
                        dataSelect={defaultMinMaxHorseFilters}
                        nameSelect={minHorseSelect}
                        onSelected={(val) => _handleSelected(val, STRING_USER_CREATE_RACE.MIN_HORSE)}
                      />
                    </div>
                  </div>
                </div>

                <div className='row height-85'>
                  {/* Race Close At */}
                  <div className='col-6'>
                    <div className='box-select'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_race_close_at`)}
                        {timeTypeSelect === TimeTypeOption.STRART_IN ? <span className='request__red'>{t(`${PROFILE_MESSAGE}.message.validate_request`)}</span> : ''}
                      </div>
                        {timeTypeSelect === TimeTypeOption.DEFAULD ? (
                          <div className='disable-box'>
                            <Form.Item
                              className='input-box disabled-opacity'
                              shouldUpdate
                            >
                              <InputPrizePool type='text' disabled={true} heigth={40} />
                            </Form.Item>
                          </div>
                          ) : (
                          <div className='start-in-contain'>
                            <div className='child-start-in'>
                              <Form.Item
                                name='dayStartIn'
                                className='input-box'
                                shouldUpdate
                              >
                                <InputPrizePool
                                  maxLength={3}
                                  type='text'
                                  onKeyDown={_handleKeyDownDay}
                                  onChange={(val) => _handleChangeDay(val, STRING_USER_CREATE_RACE.DAY)}
                                  value={values.dayStartIn}
                                  heigth={40}
                                />
                              </Form.Item>
                              <span className='pre-name'>{t(`${PROFILE_MESSAGE}.pre_day`)}</span>
                            </div>
                            <div className='child-start-in'>
                              <Form.Item
                                name='hourStartIn'
                                className='input-box'
                                shouldUpdate
                              >
                                <InputPrizePool
                                  maxLength={2}
                                  type='text'
                                  onKeyDown={_handleKeyDownDay}
                                  onChange={(val) => _handleChangeDay(val, STRING_USER_CREATE_RACE.HOUR)}
                                  value={values.hourStartIn}
                                  heigth={40}
                                />
                              </Form.Item>
                              <span className='pre-name'>{t(`${PROFILE_MESSAGE}.pre_hour`)}</span>
                            </div>
                            <div className='child-start-in'>
                              <Form.Item
                                name='minuteStartIn'
                                className='input-box'
                                shouldUpdate
                              >
                                <InputPrizePool
                                  maxLength={2}
                                  type='text'
                                  onKeyDown={_handleKeyDownDay}
                                  onChange={(val) => _handleChangeDay(val, STRING_USER_CREATE_RACE.MINUTE)}
                                  value={values.minuteStartIn}
                                  heigth={40}
                                />
                              </Form.Item>
                              <span className='pre-name'>{t(`${PROFILE_MESSAGE}.pre_minute`)}</span>
                            </div>
                            <div className='text_error'>{errorRaceCloseAt}</div>
                          </div>
                        )}
                      
                    </div>
                  </div>

                  {/* Max Horse */}
                  <div className='col-6'>
                    <div className='box-select'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_max_horse`)}
                      </div>
                      <SelectCustomCreateRace
                        dataSelect={defaultMaxHorseFilters}
                        nameSelect={maxHorseSelect}
                        onSelected={(val) => _handleSelected(val, STRING_USER_CREATE_RACE.MAX_HORSE)}
                      />
                    </div>
                  </div>
                </div>

                <div className='row height-85'>
                  {/* Entry Fee */}
                  <div className='col-6'>
                    <div className='box-select'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_entry_fee`)}
                      </div>
                      <div>
                        <Form.Item
                          name="entryFee"
                          className="input-box"
                          dependencies={['entryFee']}
                          rules={[
                            {
                              validator: (_, value) => {
                                if (value && value.length > 16) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.validate_fee_max_length`)));
                                }                                
                                if (+value && !sumPrize) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.validate_prize_empty`)));
                                }
                                if (value && sumPrize > multiEntry) {
                                  return Promise.reject(new Error(t(`${PROFILE_MESSAGE}.message.validate_prize_greater_fee`)));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputPrizePool
                            name='entryFee'
                            maxLength={16}
                            type="text"
                            onKeyDown={_handleKeyDownEntryFee}
                            onChange={_handleChangeEntryFee}
                            value={values.entryFee}
                            heigth={40}
                            placeholder={t(`${PROFILE_MESSAGE}.placeholder.entry_fee`)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row height-45'>
                  {/* Prize Pool */}
                  <div className='col-6'>
                    <div className='box-btn'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_prize_pool`)}
                      </div>
                      <div className='btn-prize-pool'>
                        <Form.Item>
                          <ButtonDetail
                            buttonName={
                              <div>
                                {t(`${PROFILE_MESSAGE}.btn_detail`)}
                              </div>
                            }
                            onClickButton={_handleClickBTNPrizePool}
                            checkError={checkErrorBtnPrize}
                            width={147}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  {/* Race Conditions */}
                  <div className='col-6'>
                    <div className='box-btn'>
                      <div className='title-label'>
                        {t(`${PROFILE_MESSAGE}.input_race_conditions`)}
                      </div>
                      <div className='btn-prize-pool'>
                        <Form.Item>
                          <ButtonDetail
                            buttonName={
                              <div>
                                {t(`${PROFILE_MESSAGE}.btn_detail`)}
                              </div>
                            }
                            onClickButton={_handleClickBTNRaceCondition}
                            width={147}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Button create */}
                <div className='row'>
                  <div className='btn-create'>
                    <Form.Item>
                      <Button
                        buttonName={
                          <div>
                            {t(`${PROFILE_MESSAGE}.btn_create`)}
                          </div>
                        }
                        onClickButton={form.submit}
                        width={147}
                        disabled={isError}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}}
        </Form>
      </>
    )
  }

  return (
    <Modal>
      <ModalUserCreateRaceStyled>
        <div className='user-create_race-modal'>
          <button className='close-btn p-0 position-absolute' role='button' onClick={handleClose}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className={`title-create-race-container`}>
            <div className={`color-primary`}>
              {t(`${PROFILE_MESSAGE}.createYourRace`)}
            </div>
            <img src={TWO_LINE} alt='' />
          </div>
          <div className={`create-race-container`}>
            {renderContent()}
          </div>
        </div>
      </ModalUserCreateRaceStyled>
      {isModalRaceCoditions && (
        <ModalRaceCoditions
          onSaveClick={handleSaveClickRaceCodition}
          toggleIsModalOpen={toggleIsModalRaceCoditions}
          onCloseButtonClick={() => toggleIsModalRaceCoditions(false)}
          conditionObj={conditionObj}
        />
      )}
      {isModalPrizePool && (
        <ModalPrizePool
          onSaveClick={handleSaveClickPrize}
          toggleIsModalOpen={toggleIsModalPrizePool}
          onCloseButtonClick={() => toggleIsModalPrizePool(false)}
          prizePool={prizePool}
          maxHorse={maxHorseSelect}
        />
      )}
      {confirmUserCreateRace && (
        <ModalConfirmUserCreateRace
          onConfirmClick={handleConfirmClick}
          toggleIsModalOpen={toggleConfirmUserCreateRace}
          onCloseButtonClick={() => toggleConfirmUserCreateRace(false)}
          region={regionSelect?.toUpperCase()}
          checkTypeFee={checkTypeFee}
        />
      )}
    </Modal>
  )
}

export default ModalUserCreateRace
