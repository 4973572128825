import styled from 'styled-components'

const HorseCareerItemStyled = styled.tr`
  background-color: ${({ theme }) => theme.color.neutral};
  clip-path: polygon(100% 0, 100% 100%, 22px 100%, 0 calc(60px - 22px), 0 0);
  cursor: pointer;
  td {
    text-align: left;
    vertical-align: middle;
    /* padding: 10px 0; */
    font-size: 16px;
    line-height: 52px;

    .class-tag {
      top: 4px;
    }

    .unit {
      font-size: 12px;
    }

    .total-prize {
      img {
        width: 20px;
        height: 20px;
        margin-top: -3px;
        margin-left: 3px;
      }
    }

    .entry-fee {
      img {
        width: 20px;
        height: 20px;
        margin-top: -3px;
        margin-left: 3px;
      }
    }
  }

  .total-prize-table {
    text-align: left;
    font-size: 18px;
    img {
      width: 20px;
      height: 20px;
      margin-top: -3px;
    }
  }

  .entry-fee-table {
    text-align: left;
    font-size: 18px;
    img {
      width: 20px;
      height: 20px;
      margin-top: -3px;
    }
  }
  .top-10 {
    top: 10px;
  }

  .box_tokyo {
    font-weight: 600;
    background-color: #ff1f6a;
    width: 80px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
  }

  .box_seoul {
    font-weight: 600;
    background-color: #1ecfcf;
    width: 80px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
  }

  .box_galaxy_road {
    font-weight: 600;
    background-color: #e6a01f;
    width: 120px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
  }

  .class-table {
    display: flex;
    align-items: center;
  }

  .contain_class_tag {
    display: block;
  }

  .race__name_box {
    width: 100%;
    display: flex;;
    flex-direction: column;
    margin: 10px 0;

    .contain__race_name_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 20px;
      margin-bottom: 5px;

      .race_title {
        width: 200px;

        .content-name-horver {
          display: block;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: start;
        }
      }

      .contain__icon {
        position: relative;
        width: 30px;
        height: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .contain__race_conditon {
      width: 100%;
      display: flex;
      text-align: left;
      line-height: 20px;
    }
  }
`

export default HorseCareerItemStyled
