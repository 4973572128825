/* eslint-disable @typescript-eslint/no-explicit-any */
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { useTranslation } from 'react-i18next'
import MemoizedImageHorsePopupClose from 'shared/MemoizedImage/HorsePopupClose'
import { Modal } from 'shared'
import { RaceConsditionsDetailStyle } from './styled'
import { STRING_RACE_CONDITIONS } from 'apps/constants'

interface MProps {
  onClose: () => void
  horseItem?: string
  horseInfo?: any
  width?: any
  height?: any
  typeCondition?: string
}

const ModalRaceConsditionsDetail = ({ typeCondition = "", onClose, width = 600, height = 190 }: MProps) => {
  const { t } = useTranslation()

  const renderContent = (type: string) => {
    let titleCondition = ""
    let dataRaceConditions = ""
    switch (type) {
      case STRING_RACE_CONDITIONS.ARMOR: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.armorWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.armorContentWeather`)
        break
      }
      case STRING_RACE_CONDITIONS.ARMOR_DISABLE: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.armorWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.armorContentDisableWeather`)
        break
      }
      case STRING_RACE_CONDITIONS.ATTRIBUTE: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.attributeWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.attributeContentWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.ATTRIBUTE_DISABLE: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.attributeWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.attributeContentDisableWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.CHARACTERISTIC: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.characteristicWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.characteristicContentWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.CHARACTERISTIC_DISABLE: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.characteristicWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.characteristicContentDisableWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.DISTANCE: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.distanceWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.distanceContentWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.DISTANCE_DISABLE: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.distanceWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.distanceContentDisableWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.DOPING: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.dopingWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.dopingContentWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.DOPING_DISABLE: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.dopingWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.dopingContentDisableWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.FIELD: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.fieldWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.fieldContentWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.FIELD_DISABLE: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.fieldWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.fieldContentDisableWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.SKIN: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.skinWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.skinContentWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.SKIN_DISABLE: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.skinWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.skinContentDisableWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.WEATHER: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.weatherWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.weatherContentWeather`)
        break;
      }
      case STRING_RACE_CONDITIONS.WEATHER_DISABLE: {
        titleCondition = t(`${NOTIFICATION_MESSAGE}.weatherWeather`)
        dataRaceConditions = t(`${NOTIFICATION_MESSAGE}.weatherContentDisableWeather`)
        break;
      }

      default:
        titleCondition = ""
        dataRaceConditions = ""
        break
    }

    return (
      <>
        <div className='title-condition'>
          {titleCondition}
        </div>
        <div>
          {dataRaceConditions}
        </div>
      </>
    )
  }
  
  return (
    <Modal onOverlayClick={onClose}>
      <RaceConsditionsDetailStyle width={width} height={height}>
        <button className='close-btn p-0 position-absolute' role='button' onClick={onClose}>
          <MemoizedImageHorsePopupClose />
        </button>
        <div className='content-box'>
          {renderContent(typeCondition)} 
        </div>
      </RaceConsditionsDetailStyle>
    </Modal>
  )
}

export default  ModalRaceConsditionsDetail
