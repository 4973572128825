/* eslint-disable @typescript-eslint/no-explicit-any */
import { HORSE_POPUP_ARMOR } from 'assets/images'
import styled from 'styled-components'

interface RaceConsditionsDetailStyleProps {
  width?: any
  height?: any
}

const RaceConsditionsDetailStyle = styled.div<RaceConsditionsDetailStyleProps>`
  position: relative;
  width: ${({ width }) => width + 'px'};
  min-height: ${({ height }) => height + 'px'};
  background: url(${HORSE_POPUP_ARMOR});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 99;
  padding: 25px 32px 30px;

  .close-btn {
    top: 29px;
    right: 29px;
    background-color: ${({ theme }) => theme.color.transparent};
    border: none;
    z-index: 100;
  }

  .content-box {
    position: relative;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 18px;
    line-height: 38px;
    padding: 10px;
  }

  .title-condition {
    color: #4ef076;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export { RaceConsditionsDetailStyle }
