/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Input } from 'antd'

import { ONE_LINE } from 'assets/images'
import InputStyled from './styled'

interface InputProps {
  value?: string
  onChange?: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
  customClass?: string
  placeholder?: string
  type?: string
  minLength?: number
  maxLength?: number
  name?: string
  width?: number
  heigth?: number
  disabled?: boolean
  onKeyDown?: (e: never) => void
  addonAfter?: string
  typePass?: boolean
}

function InputPrizePool({
  value,
  onChange,
  placeholder,
  customClass = '',
  type,
  minLength = 0,
  maxLength,
  name,
  width,
  heigth=32,
  disabled = false,
  onKeyDown,
  addonAfter,
  typePass=false,
  ...props
}: InputProps) {
  const [visible, setVisible] = useState(false)
  const inputRef = useRef<any>(null)
  const searchInputClass = classNames('position-relative', 'd-flex', 'align-items-center', customClass)

  useEffect(() => {
    getQuery()
  }, [visible])

  const onFocus = () => {
    getQuery()
  }

  const _onChange = (v: any) => {
    getQuery()
    onChange && onChange(v?.target?.value)
  }

  const getQuery = () => {
    if (inputRef.current) {
      inputRef.current.input.setAttribute("readonly", "true")
      setTimeout(() => {
        inputRef.current.input.removeAttribute("readonly")
      }, 100)
    }
  }

  const handleClick = (event: any) => {
    event.stopPropagation()
    getQuery()
  }

  return (
    <InputStyled className={searchInputClass} width={width} heigth={heigth} >
      <img src={ONE_LINE} alt='' className='orange-line position-absolute' />

      {typePass ? (
          <Input
            ref={inputRef}
            type={visible ? "text" : "password"}
            className={`search-input flex-grow-1 ${disabled ? 'input_opacity' : ''}`}
            placeholder={placeholder}
            value={value}
            onChange={_onChange}
            minLength={minLength}
            maxLength={maxLength}
            name={"new-password"}
            onKeyDown={onKeyDown}
            disabled={disabled}
            autoComplete="new-password"
            aria-autocomplete="none"
            readOnly
            onFocus={() => onFocus()}
            onClick={(e) => handleClick(e)}
            suffix={
              visible ? (
                <EyeOutlined onClick={() => setVisible(false)} />
              ) : (
                <EyeInvisibleOutlined onClick={() => setVisible(true)} />
              )
            }
          />
        ) :
        <Input
          type={type || 'text'}
          className={`search-input flex-grow-1 ${disabled ? 'input_opacity' : ''}`}
          placeholder={placeholder}
          value={value}
          onChange={_onChange}
          minLength={minLength}
          maxLength={maxLength}
          name={name}
          onKeyDown={onKeyDown}
          disabled={disabled}
          addonAfter={addonAfter}
          autoComplete="off"
          aria-autocomplete="none"
          {...props}
        />
      }
    </InputStyled>
  )
}

export default InputPrizePool
