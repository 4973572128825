/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApiResponse,
  GetRaceListParams,
  GetRaceListResponse,
} from 'models'
import axiosClient from './axiosClient'

const userCreateRaceApi = {
  getListUserCreateRace(params?: GetRaceListParams): Promise<ApiResponse<GetRaceListResponse>> {
    const url = '/user-created/races/list'
    return axiosClient.get(url, { params })
  },

  postUserCreateRace(params?: any): Promise<ApiResponse<any>> {
    const url = '/user-created/race/create'
    return axiosClient.post(url, params)
  },

  getRaceCreationLimit(): Promise<ApiResponse<any>> {
    const url = '/user-created/creation-limit'
    return axiosClient.get(url)
  },

  getFeeToCreateRace(): Promise<ApiResponse<any>> {
    const url = '/user-created/fee'
    return axiosClient.get(url)
  },

  postUserCancelRace(raceId: any): Promise<ApiResponse<any>> {
    const url = `/user-created/race/cancel/${raceId}`
    return axiosClient.post(url)
  },
}

export default userCreateRaceApi
