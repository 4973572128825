import { createGlobalStyle } from 'styled-components'

const CommonStyle = createGlobalStyle`
  body {
    font-family: 'BlenderPro-Book';
    background-color: #262626;
  }

  .font-bold {
    font-family: 'BlenderPro-Bold';
  }

  .color-primary {
    background: ${({ theme }) => theme.color.primary};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .color-secondary {
    color: ${({ theme }) => theme.color.secondary}
  }

  .color-white {
    color: ${({ theme }) => theme.color.white};
  }

  .color-neutral_gray {
    color: ${({ theme }) => theme.color.neutralGray}
  }

  .color-grey {
    color: ${({ theme }) => theme.color.grey}
  }

  .color-orange {
    color: ${({ theme }) => theme.color.orange}
  }

  .color-orange-200 {
     color: ${({ theme }) => theme.color.orange200}
  }

  .color-yellow {
    color: ${({ theme }) => theme.color.yellow} !important
  }

  .color-red {
    color: ${({ theme }) => theme.color.red}
  }

  .color-DEDEDE {
    color: #DEDEDE;
  }

  .color-turf-field {
    color: #25D8FD;
  }

  .color-kuda {
    color: #ffffff;
  }

  .color-need-kuda {
    color: #ffffff;
  }

  .color-merah {
    color: #ffffff;
  }

  .color-biru {
    color: #ffffff;
  }
  
  .color-hightlight {
    color: #4cf394;
  }

  .max-width-150 {
    width: 150px;
  }

  .max-width-50 {
    width: 50px;
  }

  .max-width-60 {
    width: 60px;
  }

  .max-width-70 {
    width: 70px;
  }

  .max-width-80 {
    width: 80px;
  }

  .min-width-130 {
    min-width: 130px;
  }

  .min-width-140 {
    min-width: 140px;
  }

  .min-width-160 {
    width: 160px;
  }

  .padding-left-header-10 {
    padding-left: 10px !important;
  }

  .padding-left-header-41 {
    padding-left: 41px !important;
  }

  .class-tag {
    margin-top: -25px;
  }

  .font-size-24 {
    font-size: 24px;
  }

  .font-size-20 {
    font-size: 20px;
  }

  .font-size-18 {
    font-size: 18px;

    pre {
      font-family: 'SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace';

      &.font-book{
        font-family: 'BlenderPro-Book';
      }
    }
  }
  .font-size-16 {
    font-size: 16px;
  }

  .body-content {
    white-space: pre-line;
  }
  .Toastify__toast-container--bottom-center {
    top: 40%
  }

  .ant-notification {
  .toast-normal {
    background-color: white;
    border-radius: 10px;
  }

  .toast-error {
    background-color: #e74c3c;
    border-radius: 10px;
  }

  .ant-notification-notice-content {
    padding-right: 0.5rem;
  }

  .toast-success {
    background-color: #107c20d6;
    border-radius: 10px;
  }
   .toast-warning {
    background-color: #f1c40f;
    border-radius: 10px;
  }

  .ant-notification-notice-description {
    font-size: 20px;
    font-weight: 700;
    color: white;
  }
  .ant-notification-notice-message {
    display: none;
  }

  .ant-custom-error {
    background: #e74c3c;

    .anticon-info-circle {
      display: none;
    }
  }

  .ant-custom-success {
    background: #107c20d6;
    .anticon-info-circle, .ant-notification-notice-icon {
      display: none;
    }
  }

  .ant_custom_success {
    background: #082a5d;
    border: 2px solid #05e1e9;

    .anticon-info-circle, .ant-notification-notice-icon {
      display: none;
    }

    .ant-notification-notice-close, .ant-notification-notice-close:hover {
      color: #05e1e9;
      top: 10px;
      right: 15px;
    }
  }
}

  
`

export default CommonStyle
