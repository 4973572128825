/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd';
import {
  BG_BUTTON,
  BTN_CANCEL,
  REMOVE_BTN,
  BTN_SELL,
  BTN_CEDE,
  BTN_SEND_TO_BLACKLIST,
  BTN_DISBAND,
  BTN_CREATE_RACE,
  BTN_DETAIL_RACE,
  BUTTON_BG
} from 'assets/images';
import ButtonStyled from './styled';

interface ButtonProps {
  onClickButton: () => void
  buttonName: string | React.ReactNode
  disabled?: boolean
  width?: any
  isLoading?: boolean
  height?: any
  classNames?: any
  type?: string
  margingTop?: boolean
}

function Button({
  onClickButton,
  buttonName,
  disabled,
  width = 215,
  isLoading,
  height = 45,
  type = "default",
  margingTop = false,
  classNames
}: ButtonProps) {

  const handleClick = () => {
    if (disabled || isLoading) return
    onClickButton()
  }

  const checkBtn = () => {
    switch (type) {
      case "btnDisband": {
        return BTN_DISBAND
      }
      case "btnSell": {
        return BTN_SELL
      }
      case "btnCede": {
        return BTN_CEDE
      }
      case "btnRemove": {
        return BTN_DISBAND
      }
      case "btnSendToBlacklist": {
        return BTN_SEND_TO_BLACKLIST
      }
      case "btnCancel": {
        return BTN_CANCEL
      }
      case "btnView": {
        return REMOVE_BTN
      }
      case "btnCreateRace": {
        return BTN_CREATE_RACE
      }
      case "btnCancelCreateRace": {
        return BTN_DETAIL_RACE
      }
      case "btnSelectAll": {
        return BUTTON_BG
      }
      case "default":
      default:
        return BG_BUTTON
    }
  }

  return (
    <ButtonStyled className={classNames} checkBtn={checkBtn} height={height} width={width}>
      <div className={`btn-container-${disabled || isLoading ? 'disable' : ''} background-btn`} onClick={handleClick} >
        <span className='btn-name'>{isLoading && <Spin className={`${margingTop ? 'mr-3' : 'mt-2 mr-3'}`} />}{buttonName}</span>
        {/* <img src={checkBtn()} alt='' className='background-btn' width={width} height={height} /> */}
      </div>
    </ButtonStyled>
  )
}

export default Button
