/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { PROFILE_MESSAGE } from "i18n/constants";
import { useAppDispatch } from 'hooks'
import { constants } from 'apps'
import { RaceTable } from 'features/Race/components'
import { GetRaceListParams, RaceStatus, RecordRace } from 'models'
import { userCreateRaceCurrentColumns, userCreateRaceHistoryColumns } from 'utils/columns'
// import { shortenRaceCourseName } from 'utils/helper'
import { NUMBER, STRING_STATUS } from 'apps/constants'
import TableUserCreateRaceStyled from './styled'
import { errorUserCreateRaceSelector, getListUserCreateRaceSelector, userCreateRaceSuccessSelector } from 'apps/redux/userCreateRace/selector';
import { getListUserCreateRace } from 'apps/redux/userCreateRace/action';
import { notification } from 'antd';
import { WS_MANAGER } from 'socket/socketClient';
import { setNullErrorUserCreateRace, setNullUserCreateRaceSuccess } from 'apps/redux/userCreateRace/userCreateRace.slice';

interface raceprops {
  tabActive: string
  refresh?: boolean
}

const defaultParams: GetRaceListParams = {
  limit: NUMBER.TWENTY,
  page: NUMBER.ZERO,
  status: RaceStatus.OPEN
}

function TableUserCreateRace({tabActive, refresh = false}: raceprops) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const listUserCreateRaceSelector = useSelector(getListUserCreateRaceSelector)
  const errorCreateRaceSelector = useSelector(errorUserCreateRaceSelector)
  const createRaceSuccessSelector = useSelector(userCreateRaceSuccessSelector)
  const token =
    localStorage.getItem(constants.ACCESS_TOKEN_KEY) && localStorage.getItem(constants.ACCESS_TOKEN_KEY) !== 'null'
      ? localStorage.getItem(constants.ACCESS_TOKEN_KEY)
      : false
  const [params, setParams] = useState<GetRaceListParams>(defaultParams)
  const [races, setRaces] = useState<RecordRace[]>([])
  const [loader, setLoader] = useState(true)
  const [totalRace, setTotalRace] = useState(NUMBER.ZERO)
  const memoizedUserCreateRaceColumns = useMemo(() => 
    tabActive === t(`${PROFILE_MESSAGE}.tabCurrent`) ? userCreateRaceCurrentColumns : userCreateRaceHistoryColumns
  , [tabActive])

  useLayoutEffect(() => {
    const handleLoadingRaces = () => {
      if (races.length < NUMBER.TWENTY && races.length > NUMBER.ZERO) {
        setLoader(true)
      }
    }

    handleLoadingRaces()
  }, [])

  const getDefauldList = async () => {
    await setParams({
      ...params,
      status: tabActive === t(`${PROFILE_MESSAGE}.tabCurrent`) ? RaceStatus.OPEN : RaceStatus.CLOSED,
      page: NUMBER.ONE
    })
    setRaces([])
  }

  useEffect(() => {
    if (tabActive || refresh) {
      getDefauldList()
    }
  }, [tabActive, refresh])

  useEffect(() => {
    if (errorCreateRaceSelector) {
      notification.error({
        message: 'ERROR',
        description: errorCreateRaceSelector ?? 'Error',
        placement: 'bottomRight',
        duration: 4,
        className: 'ant-custom-error',
        icon: <></>
      })
      dispatch(setNullErrorUserCreateRace())
    }
  }, [errorCreateRaceSelector])

  useEffect(() => {
    if (createRaceSuccessSelector) {
      notification.success({
        message: 'SUCCESS',
        description: createRaceSuccessSelector ?? 'You have created race successfully',
        placement: 'bottomRight',
        duration: 4,
        className: 'ant-custom-success',
        icon: <></>
      })
      dispatch(setNullUserCreateRaceSuccess())
    }
  }, [createRaceSuccessSelector])

  useEffect(() => {
    if (token && params.page > NUMBER.ZERO) {
      fetchListRaces()
    }
    const subscription = WS_MANAGER.subscribe('/topic/race-status', handleSocketChange)
    return () => {
      subscription?.then(sub => sub?.unsubscribe())
    }
  }, [params])

  const fetchListRaces = async () => {
    setLoader(false)
    await dispatch(getListUserCreateRace({ ...params, page: params.page }))
    setLoader(true)
  }

  const handleSocketChange = (message: { body: string }) => {
    const { data } = JSON.parse(message.body)
    const { newStatus } = data[Object.keys(data)[NUMBER.ZERO]]
    
    if (newStatus === STRING_STATUS.CANCEL || newStatus === STRING_STATUS.RESULT || newStatus === STRING_STATUS.CLOSED) {
      document.body.scrollTop = NUMBER.ZERO
      document.documentElement.scrollTop = NUMBER.ZERO
      getDefauldList()
    }
  }

  useEffect(() => {
    const records = listUserCreateRaceSelector?.records
    setTotalRace(listUserCreateRaceSelector?.total || NUMBER.ZERO)
    if (records && records?.length > NUMBER.ZERO) {
      const newRace = [
        ...new Map(
          [...races, ...records].map((item: any) => [
            `${item?.id}`,
            item,
          ])
        ).values(),
      ]
      setRaces(newRace)
    }
  }, [listUserCreateRaceSelector])

  const clonedRates = races
  // .reduce((accumulator: any, race: any) => {
  //   race.course.name = shortenRaceCourseName(race?.course?.name)
  //   if (!race.name.includes("   ")) {
  //     accumulator.push(race);
  //   }
  //   return accumulator;
  // }, [])

  return (
    <TableUserCreateRaceStyled>
      <div className='content-container pt-0'>
        <RaceTable
          columns={memoizedUserCreateRaceColumns}
          data={clonedRates ?? []}
          isRowClickable
          raisePage={setParams}
          loader={loader}
          params={params}
          totalRace={totalRace}
        />
      </div>
    </TableUserCreateRaceStyled>
  )
}

export default TableUserCreateRace
