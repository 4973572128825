/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import userCreateRaceApi from "apis/userCreateRaceApi";
import { GetRaceListParams } from "models";
import { handleAsyncRequest } from 'utils/helper'

export const getListUserCreateRace  = createAsyncThunk(
  'userCreateRace/getListUserCreateRace',
  async (params?: GetRaceListParams) => {
    const [error, result] = await handleAsyncRequest(userCreateRaceApi.getListUserCreateRace(params))
    if (error) {
      return (error as any)?.message
    }
    return (result as any)?.data
  }
)

export const postUserCreateRace  = createAsyncThunk(
  'userCreateRace/postUserCreateRace',
  async (params?: any) => {
    const [error, result] = await handleAsyncRequest(userCreateRaceApi.postUserCreateRace(params))
    if (error) {
      return error
    }
    return result
  }
)

export const getRaceCreationLimit  = createAsyncThunk(
  'userCreateRace/getRaceCreationLimit',
  async () => {
    const [error, result] = await handleAsyncRequest(userCreateRaceApi.getRaceCreationLimit())
    if (error) {
      return (error as any)?.message
    }
    return (result as any)?.data
  }
)

export const getFeeToCreateRace  = createAsyncThunk(
  'userCreateRace/getFeeToCreateRace',
  async () => {
    const [error, result] = await handleAsyncRequest(userCreateRaceApi.getFeeToCreateRace())
    if (error) {
      return (error as any)?.message
    }
    return (result as any)?.data
  }
)

export const postUserCancelRace  = createAsyncThunk(
  'userCreateRace/postUserCancelRace',
  async (raceId?: any) => {
    const [error, result] = await handleAsyncRequest(userCreateRaceApi.postUserCancelRace(raceId))
    if (error) {
      return error
    }
    return (result as any)?.data
  }
)
