import { RootState } from "apps/store";

export const getListUserCreateRaceSelector = (state: RootState) => state.userCreateRace.userCreateRace

export const loadingUserCreateRaceSelector = (state: RootState) => state.userCreateRace.loadingUserCreateRace

export const errorUserCreateRaceSelector = (state: RootState) => state.userCreateRace.errorUserCreateRace

export const userCreateRaceSuccessSelector = (state: RootState) => state.userCreateRace.userCreateRaceSuccess

export const getLimitRaceSelector = (state: RootState) => state.userCreateRace.limit

export const getFeeRaceSelector = (state: RootState) => state.userCreateRace.fee

export const errorFeeSelector = (state: RootState) => state.userCreateRace.errorFee

export const errorLimitSelector = (state: RootState) => state.userCreateRace.errorLimit

export const errorCancelUserCreateRaceSelector = (state: RootState) => state.userCreateRace.errorCancelUserCreateRace

export const loadingCancelUserCreateRaceSelector = (state: RootState) => state.userCreateRace.loadingCancelUserCreateRace

export const dataInputSelector = (state: RootState) => state.userCreateRace.dataInput
