/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { paths } from 'apps'
import { TWO_LINE } from 'assets/images'
import { useAppDispatch, useAppSelector, useToggle } from 'hooks'
import { PROFILE_MESSAGE } from 'i18n/constants'
import Button from 'shared/Button'
import TabsUserCreateRace from 'features/Profile/components/TabsUserCreateRace'
import TableUserCreateRace from 'features/Profile/components/TableUserCreateRace'
import ModalUserCreateRace from 'features/Profile/components/ModalUserCreateRace'
import { errorCancelUserCreateRaceSelector } from 'apps/redux/userCreateRace/selector'
import { useSelector } from 'react-redux'
import { notification } from 'antd'
import { handleAsyncRequest } from 'utils/helper'
import { getCurrentUser } from 'utils/metamask'
import { saveDataInput } from 'apps/redux/userCreateRace/userCreateRace.slice'
import UserCreateRaceStyled from './styled'

export default function UserCreateRace() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const errorCancelUserCreateRace = useSelector(errorCancelUserCreateRaceSelector)
  const [tabActive, setTabActive] = useState<any>(t(`${PROFILE_MESSAGE}.tabCurrent`))
  const auth = useAppSelector(state => state.auth)
  const [isModalUserCreateRace, toggleIsModalUserCreateRace] = useToggle(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    dispatch(saveDataInput({}))
  }, [])

  useEffect(() => {
    if (errorCancelUserCreateRace) {
      notification.error({
        message: 'ERROR',
        description: errorCancelUserCreateRace ?? 'Error',
        placement: 'bottomRight',
        duration: 4,
        className: 'ant-custom-error',
        icon: <></>
      })
    }
  }, [errorCancelUserCreateRace])

  const fetchCurrent = async () => {
    const [error,]: any = await handleAsyncRequest(getCurrentUser())
    if (error?.code === 403) {
      window.location.href = paths.auth.login()
    }
  }

  useEffect(() => {
    if (!auth.isLogged) {
      fetchCurrent()
    }
  }, [auth])

  const handleCreateRace = () => {
    toggleIsModalUserCreateRace(true)
  }

  const _onTabClick = (tab: string) => {
    setTabActive(tab)
  }

  const handleCloseUserCreateRace = () => {
    toggleIsModalUserCreateRace(false)
  }

  const handleConfirmClick = () => {
    setRefresh(true)
    const timeOut = setTimeout(() => {
      setRefresh(false)
      clearTimeout(timeOut)
    }, 0)
  }

  return (
    <UserCreateRaceStyled>
      <div className='container'>
        <div className='head-create-race-container'>
          <div className='head-create-race'>
            <div className='title font-bold'>{t(`${PROFILE_MESSAGE}.titleCreateRace`)}</div>
            <img src={TWO_LINE} alt='' className='line' />
          </div>

          {/* button create race */}
          <div className='button-box'>
            <Button
              buttonName={t(`${PROFILE_MESSAGE}.btnCreateRace`)}
              onClickButton={handleCreateRace}
              type="btnCreateRace"
              height={52}
              width={181}
            />
          </div>
        </div>

        {/* tabs */}
        <div className='box-tabs-current-race'>
          <TabsUserCreateRace onTabClick={_onTabClick} />
        </div>
        {/* table child tab */}
        <TableUserCreateRace tabActive={tabActive} refresh={refresh} />

        {isModalUserCreateRace && (
          <ModalUserCreateRace
            onConfirmCreateRaceClick={handleConfirmClick}
            toggleIsModalOpen={toggleIsModalUserCreateRace}
            onCloseButtonClick={handleCloseUserCreateRace}
          />
        )}
      </div>
    </UserCreateRaceStyled>
  )
}
