/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useState } from 'react'
import { useToggle } from 'hooks'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import {
  ARMOR_DISABLE_WEATHER,
  ARMOR_WEATHER,
  ATTRIBUTE_DISABLE_WEATHER,
  ATTRIBUTE_WEATHER,
  CHARACTERISTIC_DISABLE_WEATHER,
  CHARACTERISTIC_WEATHER,
  DISABLE_RACE_CONDITION_WEATHER,
  DISTANCE_DISABLE_WEATHER,
  DISTANCE_WEATHER,
  DOPING_DISABLE_WEATHER,
  DOPING_WEATHER,
  FIELD_DISABLE_WEATHER,
  FIELD_WEATHER,
  SKIN_DISABLE_WEATHER,
  SKIN_WEATHER,
  WEATHER_DISABLE_WEATHER,
  WEATHER_WEATHER
} from 'assets/images'
import ModalRaceConsditionsDetail from '../ModalRaceConsditionsDetail'
import ImageRaceConditionsContainer from "./styled"
import { STRING_RACE_CONDITIONS } from 'apps/constants'
import { useState } from 'react'

interface RaceTypeProps {
  width?: number
  height?: number
  marginRigth?: number
  margin?: number
  raceConditions?: any
  raceWeather?: any
  colunm2Line?: boolean
  pageOfRace?: string
}

function ImageRaceConditions({
  width = 36,
  height = 36,
  marginRigth = 0,
  margin = 3,
  raceConditions = null,
  raceWeather = null,
  colunm2Line = true,
  pageOfRace = 'OTHER'
}: RaceTypeProps) {
  const [typeCondition, setTypeCondition] = useState("")
  const [openWeatherodal, toggleOpenWeatherModal] = useToggle(false)

  const _handleClickImage = (event: React.MouseEvent, type: string) => {
    event.stopPropagation()
    setTypeCondition(type)
    toggleOpenWeatherModal(true)
  }  

  const _handleClosePopup = () => {
    toggleOpenWeatherModal(false)
  }

  const renderImageRaceConditions = () => {
    return <div className={`contain__img_race_condition ${colunm2Line ? "flex_direction_colunm" : ""}`}>
        <div className='child_contain__img_race_condition'>
          <div className='img_race_condition'>
            {!raceConditions?.field || raceConditions === null ?
            (<div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.FIELD)}
              className='pointer_race_condition'
            >
              <MemoizedLoadImageCommon srcImg={FIELD_WEATHER} /> 
            </div>) : (
              <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.FIELD_DISABLE)}
                className='pointer_race_condition'
              >
                <MemoizedLoadImageCommon srcImg={FIELD_DISABLE_WEATHER} /> 
                <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
              </div>
            )}
          </div>

          <div className='img_race_condition'>
            {!raceConditions?.distance || raceConditions === null ?
            (<div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.DISTANCE)}
              className='pointer_race_condition'
            >
              <MemoizedLoadImageCommon srcImg={DISTANCE_WEATHER} /> 
            </div>) : (
              <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.DISTANCE_DISABLE)}
                className='pointer_race_condition'
              >
                <MemoizedLoadImageCommon srcImg={DISTANCE_DISABLE_WEATHER} />
                <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
              </div>
            )}
          </div>

          <div className='img_race_condition'>
            {pageOfRace === 'GUILD' ? (
              (!raceConditions?.weather || raceConditions === null) ?
                (
                  <div
                    data-value='child'
                    onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.WEATHER)}
                    className='pointer_race_condition'
                  >
                    <MemoizedLoadImageCommon srcImg={WEATHER_WEATHER} /> 
                  </div>
                ) : (
                  <div
                    data-value='child'
                    onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.WEATHER_DISABLE)}
                    className='pointer_race_condition'
                  >
                    <MemoizedLoadImageCommon srcImg={WEATHER_DISABLE_WEATHER} />
                    <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
                  </div>
                )
            ) : (
              (!raceConditions?.weather || raceConditions === null) && raceWeather ?
                (
                  <div
                    data-value='child'
                    onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.WEATHER)}
                    className='pointer_race_condition'
                  >
                    <MemoizedLoadImageCommon srcImg={WEATHER_WEATHER} /> 
                  </div>
                ) : (
                  <div
                    data-value='child'
                    onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.WEATHER_DISABLE)}
                    className='pointer_race_condition'
                  >
                    <MemoizedLoadImageCommon srcImg={WEATHER_DISABLE_WEATHER} />
                    <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
                  </div>
                )
            )}
          </div>

          <div className='img_race_condition'>
            {!raceConditions?.characteristic || raceConditions === null ?
            (<div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.CHARACTERISTIC)}
              className='pointer_race_condition'
            >
              <MemoizedLoadImageCommon srcImg={CHARACTERISTIC_WEATHER} /> 
            </div>) : (
              <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.CHARACTERISTIC_DISABLE)}
                className='pointer_race_condition'
              >
                <MemoizedLoadImageCommon srcImg={CHARACTERISTIC_DISABLE_WEATHER} />
                <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
              </div>
            )}
          </div>
        </div>
        
        <div className='child_contain__img_race_condition no_margin_top'>
          <div className='img_race_condition'>
            {!raceConditions?.attribute || raceConditions === null ?
            (<div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.ATTRIBUTE)}
              className='pointer_race_condition'
            >
              <MemoizedLoadImageCommon srcImg={ATTRIBUTE_WEATHER} /> 
            </div>) : (
              <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.ATTRIBUTE_DISABLE)}
                className='pointer_race_condition'
              >
                <MemoizedLoadImageCommon srcImg={ATTRIBUTE_DISABLE_WEATHER} />
                <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
              </div>
            )}
          </div>

          <div className='img_race_condition'>
            {!raceConditions?.doping || raceConditions === null ?
            (<div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.DOPING)}
              className='pointer_race_condition'
            >
              <MemoizedLoadImageCommon srcImg={DOPING_WEATHER} /> 
            </div>) : (
              <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.DOPING_DISABLE)}
                className='pointer_race_condition'
              >
                <MemoizedLoadImageCommon srcImg={DOPING_DISABLE_WEATHER} />
                <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
              </div>
            )}
          </div>

          <div className='img_race_condition'>
            {!raceConditions?.armor || raceConditions === null ?
            (<div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.ARMOR)}
              className='pointer_race_condition'
            >
              <MemoizedLoadImageCommon srcImg={ARMOR_WEATHER} /> 
            </div>) : (
              <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.ARMOR_DISABLE)}
                className='pointer_race_condition'
              >
                <MemoizedLoadImageCommon srcImg={ARMOR_DISABLE_WEATHER} />
                <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
              </div>
            )}
          </div>

          <div className='img_race_condition'>
            {!raceConditions?.skin || raceConditions === null ?
            (<div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.SKIN)}
              className='pointer_race_condition'
            >
              <MemoizedLoadImageCommon srcImg={SKIN_WEATHER} /> 
            </div>) : (
              <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_RACE_CONDITIONS.SKIN_DISABLE)}
                className='pointer_race_condition'
              >
                <MemoizedLoadImageCommon srcImg={SKIN_DISABLE_WEATHER} />
                <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
              </div>
            )}
          </div>
        </div>
      </div>
  }

  return (
    <ImageRaceConditionsContainer
      width={width}
      height={height}
      marginRigth={marginRigth}
      margin={margin}
    >
      {renderImageRaceConditions()}

      {openWeatherodal && (
        <ModalRaceConsditionsDetail
          typeCondition={typeCondition}
          onClose={_handleClosePopup}
        />
      )}
    </ImageRaceConditionsContainer>
  )
}

export default ImageRaceConditions