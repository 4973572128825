import styled from 'styled-components'

const ModalUserCreateRaceStyled = styled.div`
  width: 952px;
  position: relative;
  background-color: #4df285;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%, 0 calc(100% - 40px));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;

  .user-create_race-modal {
    background-color: ${({ theme }) => theme.color.neutral};
    clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%, 0 calc(100% - 40px));
    padding: 8px 30px 15px;
    position: relative;
    min-height: 300px;
    min-width: 950px;
    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: ${({ theme }) => theme.color.transparent};
      border: none;
      z-index: 1;
    }

    .line {
      border-bottom: 2px solid ${({ theme }) => theme.color.black};
    }

    .height-85 {
      height: 90px;
    }

    .height-45 {
      height: 45px;
      margin-top: 10px;
    }

    .title-create-race-container {
      padding-bottom: 10px;
      font-size: 30px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
    }

    .create-race-container {
      display: flex;
      align-items: center;
      .ant-form {
        width: 100%;
      }

      .color-DEDEDE {
        color: #DEDEDE;
      }

      .title-label {
        display: flex;
        align-items: center;
        font-size: 18px;
      }

      .request__red {
        color: red;
        padding-left: 5px;
      }

      .box-select {
        width: 100%;
        position: relative;
      }

      .select-name {
        color: #ffffff;
      }

      .box-btn {
        width: 100%;
        height: 50px;
        display: flex;

        .btn-prize-pool {
          padding-left: 20px;
        }
      }

      .disable-box {
        .align-items-center {
          background-color: #1e2236;
        }
      }

      .start-in-contain {
        display: flex;
        justify-content: space-between;
        position: relative;

        .child-start-in {
          width: 30%;
          position: relative;

          .pre-name {
            position: absolute;
            top: 8px;
            right: 15px;
            color: rgb(157, 157, 157);
            font-size: 18px;
          }
        }

        .text_error {
          position: absolute;
          top: 44px;
          color: #ff4d4f;
          font-size: 16px;
          width: 100%;
        }
      }

      .btn-create {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        width: 100%;

        .ant-form-item {
          margin: 0;
        }
      }

      .ant-form-item-explain-error {
        font-size: 16px;
      }
    }
    .disabled-opacity {
      opacity: 0.3;
    }
  }
`

export default ModalUserCreateRaceStyled
