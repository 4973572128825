/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CLOSE_BTN, GAME_TOKEN_KUDA, GAME_TOKEN_MERAH } from 'assets/images'
import { Modal } from 'shared'
import { PROFILE_MESSAGE } from 'i18n/constants'
import { notification, Spin } from 'antd'
import Button from 'shared/Button'
import { useAppDispatch } from 'hooks'
import { getFeeToCreateRace, getRaceCreationLimit } from 'apps/redux/userCreateRace/action'
import { errorFeeSelector, errorLimitSelector, getFeeRaceSelector, getLimitRaceSelector, loadingUserCreateRaceSelector } from 'apps/redux/userCreateRace/selector'
import { useSelector } from 'react-redux'
import ModalConfirmUserCreateRaceStyled from './styled'
import { CURRENCY_TYPE, NUMBER } from 'apps/constants'
import userApi from 'apis/userApi'
import { handleAsyncRequest } from 'utils/helper'

interface ModalConfirmUserCreateRaceProps {
  onConfirmClick?: (value?: any) => void
  toggleIsModalOpen: (value?: boolean) => void
  onCloseButtonClick: () => void
  region?: string
  checkTypeFee?: string
}

function ModalConfirmUserCreateRace({
  toggleIsModalOpen,
  onCloseButtonClick,
  onConfirmClick = () => {},
  region,
  checkTypeFee = 'free'
}: ModalConfirmUserCreateRaceProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const limitRaceSelector = useSelector(getLimitRaceSelector)
  const feeRaceSelector = useSelector(getFeeRaceSelector)
  const errorFee = useSelector(errorFeeSelector)
  const errorLimit = useSelector(errorLimitSelector)
  const loadingUserCreateRace = useSelector(loadingUserCreateRaceSelector)
  const [limitRace, setLimitRace] = useState(NUMBER.ZERO)
  const [feeRace, setFeeRace] = useState(NUMBER.ZERO)
  const [currency, setcurrency] = useState(CURRENCY_TYPE.KUDA_NEW)
  const [coinUser, setCoinUser] = useState<any>("")
  const [disableCreate, setDisableCreate] = useState<boolean>(false)

  const fetchCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return

    setCoinUser(resultCoinUser.data)
  }
  
  useEffect(() => {
    dispatch(getRaceCreationLimit())
    dispatch(getFeeToCreateRace())
    fetchCoinUser()
  }, [])

  useEffect(() => {
    if (limitRaceSelector) {
      const limit = limitRaceSelector?.race_creation_limit_by_regions?.find((limit: any) => limit?.region === region)
      setLimitRace(limit?.count || NUMBER.ZERO)
    }
  }, [limitRaceSelector])

  useEffect(() => {
    if (feeRaceSelector) {
      const fee = feeRaceSelector?.fee_create_race?.find((fee: any) => fee?.region === region)
      setFeeRace(fee[checkTypeFee]?.fee || NUMBER.ZERO)
      setcurrency(fee[checkTypeFee]?.currency || CURRENCY_TYPE.KUDA_NEW)
    }
  }, [feeRaceSelector])

  useEffect(() => {
    if (coinUser) {
      const coin = coinUser?.items?.find((coin: any) => coin?.item_type === currency)
      if (coin?.amount < feeRace) {
        setDisableCreate(true)
      } else {
        setDisableCreate(false)
      }
    }
  }, [coinUser])

  useEffect(() => {
    if (errorFee) {
      notification.error({
        message: 'ERROR',
        description: errorFee ?? 'Error',
        placement: 'bottomRight',
        duration: 4,
        className: 'ant-custom-error',
        icon: <></>
      })
    }
  }, [errorFee])

  useEffect(() => {
    if (errorLimit) {
      notification.error({
        message: 'ERROR',
        description: errorLimit ?? 'Error',
        placement: 'bottomRight',
        duration: 4,
        className: 'ant-custom-error',
        icon: <></>
      })
    }
  }, [errorLimit])

  const handleBackModalCancel = () => {
    onCloseButtonClick()
  }

  const renderContent = () => {
    return (
      <div className='confirm-container color-DEDEDE'>
        <div className='row'>
          <div>{t(`${PROFILE_MESSAGE}.confirm_note_title`)}<br/><br/></div>
          <div>{t(`${PROFILE_MESSAGE}.confirm_note1`)}</div>
          <div>
            {t(`${PROFILE_MESSAGE}.confirm_note2_1`)}
            <span className='limit-race'>{limitRace}</span>
            {t(`${PROFILE_MESSAGE}.confirm_note2_2`)}
          </div>
          <div>
            {t(`${PROFILE_MESSAGE}.confirm_note3`)}
            <span className='limit-race'>{feeRace}</span>
            {currency === CURRENCY_TYPE.KUDA_NEW ?
              <img src={GAME_TOKEN_KUDA} width={20} /> : 
              <img src={GAME_TOKEN_MERAH} width={20} />}
          </div>
          <div>{t(`${PROFILE_MESSAGE}.confirm_note4`)}</div>
        </div>
        
        <div className='row'>
          <div className='btn-create d-flex align-items-center justify-content-center'>
            <Button
              buttonName={t(`${PROFILE_MESSAGE}.btn_back`)}
              onClickButton={handleBackModalCancel}
              width={150}
              type="btnCancel"
            />
            <Button
              buttonName={<div className='d-flex align-items-center'>
                            {feeRace} 
                            {currency === CURRENCY_TYPE.KUDA_NEW ?
                              <img src={GAME_TOKEN_KUDA} width={20} /> : 
                              <img src={GAME_TOKEN_MERAH} width={20} />}
                          </div>}
              onClickButton={onConfirmClick}
              width={150}
              margingTop={true}
              isLoading={loadingUserCreateRace}
              disabled={disableCreate}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      <ModalConfirmUserCreateRaceStyled>
        <div className='user-create_race-modal'>
          <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className={`title-create-race-container line-condition`}>
            <div className={`color-primary`}>
              {t(`${PROFILE_MESSAGE}.createYourRace`)}
            </div>
          </div>
          <div className={`create-race-container`}>
            {coinUser ? renderContent() : (
              <div className='loading-confirm'>
                <Spin />
              </div>
            )}
          </div>
        </div>
      </ModalConfirmUserCreateRaceStyled>
    </Modal>
  )
}

export default ModalConfirmUserCreateRace
