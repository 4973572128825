/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ARMOR_DISABLE_WEATHER, ARMOR_WEATHER, ATTRIBUTE_DISABLE_WEATHER, ATTRIBUTE_WEATHER, CHARACTERISTIC_DISABLE_WEATHER, CHARACTERISTIC_WEATHER, CLOSE_BTN, DISABLE_RACE_CONDITION_WEATHER, DISTANCE_DISABLE_WEATHER, DISTANCE_WEATHER, DOPING_DISABLE_WEATHER, DOPING_WEATHER, FIELD_DISABLE_WEATHER, FIELD_WEATHER, SKIN_DISABLE_WEATHER, SKIN_WEATHER, WEATHER_DISABLE_WEATHER, WEATHER_WEATHER } from 'assets/images'
import { Modal } from 'shared'
import { PROFILE_MESSAGE } from 'i18n/constants'
import { Form } from 'antd'
import Button from 'shared/Button'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import ModalRaceCoditionsStyled from './styled'

interface ModalRaceCoditionsProps {
  onSaveClick?: (value?: any) => void
  toggleIsModalOpen: (value?: boolean) => void
  onCloseButtonClick: () => void
  conditionObj?: object | undefined
}

function ModalRaceCoditions({
  onCloseButtonClick,
  onSaveClick = () => {},
  conditionObj = {}
}: ModalRaceCoditionsProps) {
  const { t } = useTranslation()
  const defaulPrizePool = useRef(JSON.parse(JSON.stringify(conditionObj))).current
  const [valCheckboxField, setValCheckboxField] = useState<boolean>(() => defaulPrizePool.field) as any
  const [valCheckboxDistance, setValCheckboxDistance] = useState<boolean>(() => defaulPrizePool.distance) as any
  const [valCheckboxWeather, setValCheckboxWeather] = useState<boolean>(() => defaulPrizePool.weather) as any
  const [valCheckboxCharacteristic, setValCheckboxCharacteristic] = useState<boolean>(() => defaulPrizePool.characteristic) as any
  const [valCheckboxAttribute, setValCheckboxAttribute] = useState<boolean>(() => defaulPrizePool.attribute) as any
  const [valCheckboxDoping, setValCheckboxDoping] = useState<boolean>(() => defaulPrizePool.doping) as any
  const [valCheckboxArmor, setValCheckboxArmor] = useState<boolean>(() => defaulPrizePool.armor) as any
  const [valCheckboxSkin, setValCheckboxSkin] = useState<boolean>(() => defaulPrizePool.skin) as any
  const [checkEnableBTN, setCheckEnableBTN] = useState<boolean>(true)

  useEffect(() => {
    getCheckBtn()
  }, [
    valCheckboxField,
    valCheckboxDistance,
    valCheckboxWeather,
    valCheckboxCharacteristic,
    valCheckboxAttribute,
    valCheckboxDoping,
    valCheckboxArmor,
    valCheckboxSkin
  ])
  
  const onChangeCheckbox = (value: boolean, type: string) => {
    switch (type) {
      case "Field": {
        setValCheckboxField(value)
        break;
      }
      case "Distance": {
        setValCheckboxDistance(value)
        break;
      }
      case "Weather": {
        setValCheckboxWeather(value)
        break;
      }
      case "Characteristic": {
        setValCheckboxCharacteristic(value)
        break;
      }
      case "Attribute": {
        setValCheckboxAttribute(value)
        break;
      }
      case "Doping": {
        setValCheckboxDoping(value)
        break;
      }
      case "Armor": {
        setValCheckboxArmor(value)
        break;
      }
      case "Skin": {
        setValCheckboxSkin(value)
        break;
      }
    
      default:
        break;
    }
  }

  const handleSaveRaceCondition = () => {
    const res = {
      armor: valCheckboxArmor,
      attribute: valCheckboxAttribute,
      characteristic: valCheckboxCharacteristic,
      distance: valCheckboxDistance,
      doping: valCheckboxDoping,
      field: valCheckboxField,
      skin: valCheckboxSkin,
      weather: valCheckboxWeather
    }
    onSaveClick(res)
    onCloseButtonClick()
  }

  const getCheckBtn = () => {
    if (valCheckboxField &&
      valCheckboxDistance &&
      valCheckboxWeather &&
      valCheckboxCharacteristic &&
      valCheckboxAttribute &&
      valCheckboxDoping &&
      valCheckboxArmor &&
      valCheckboxSkin
    ) {
      setCheckEnableBTN(false)
    } else {
      setCheckEnableBTN(true)
    }
  }

  const handleSelectAllRaceCondition = () => {
    if (checkEnableBTN) {
      setValCheckboxField(true)
      setValCheckboxDistance(true)
      setValCheckboxWeather(true)
      setValCheckboxCharacteristic(true)
      setValCheckboxAttribute(true)
      setValCheckboxDoping(true)
      setValCheckboxArmor(true)
      setValCheckboxSkin(true)
      setCheckEnableBTN(false)
    } else {
      setValCheckboxField(false)
      setValCheckboxDistance(false)
      setValCheckboxWeather(false)
      setValCheckboxCharacteristic(false)
      setValCheckboxAttribute(false)
      setValCheckboxDoping(false)
      setValCheckboxArmor(false)
      setValCheckboxSkin(false)
      setCheckEnableBTN(true)
    }
  }

  const renderContent = () => {
    return (
      <>
        <Form>
          <div className='color-DEDEDE'>
            <div className='row'>
              {/* Field */}
              <div className='condition__checkbox'>
                <div className='condition__checkbox--text'>{t(`${PROFILE_MESSAGE}.title_field`)}</div>
                <div className='guil__content--checkbox mg__top--0'>
                  {valCheckboxField ? (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(false, 'Field')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={FIELD_WEATHER} />
                    </div>
                  ) : (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(true, 'Field')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={FIELD_DISABLE_WEATHER} />
                      <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
                    </div>
                  )}
                </div>
              </div>

              {/* Distance */}
              <div className='condition__checkbox'>
                <div className='condition__checkbox--text'>{t(`${PROFILE_MESSAGE}.title_distance`)}</div>
                <div className='guil__content--checkbox mg__top--0'>
                  {valCheckboxDistance ? (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(false, 'Distance')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={DISTANCE_WEATHER} />
                    </div>
                  ) : (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(true, 'Distance')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={DISTANCE_DISABLE_WEATHER} />
                      <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
                    </div>
                  )}
                </div>
              </div>

              {/* Weather */}
              <div className='condition__checkbox'>
                <div className='condition__checkbox--text'>{t(`${PROFILE_MESSAGE}.title_weather`)}</div>
                <div className='guil__content--checkbox mg__top--0'>
                  {valCheckboxWeather ? (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(false, 'Weather')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={WEATHER_WEATHER} />
                    </div>
                  ) : (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(true, 'Weather')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={WEATHER_DISABLE_WEATHER} />
                      <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
                    </div>
                  )}
                </div>
              </div>

              {/* Characteristic */}
              <div className='condition__checkbox'>
                <div className='condition__checkbox--text'>{t(`${PROFILE_MESSAGE}.title_characteristic`)}</div>
                <div className='guil__content--checkbox mg__top--0'>
                  {valCheckboxCharacteristic ? (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(false, 'Characteristic')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={CHARACTERISTIC_WEATHER} />
                    </div>
                  ) : (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(true, 'Characteristic')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={CHARACTERISTIC_DISABLE_WEATHER} />
                      <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
                    </div>
                  )}
                </div>
              </div>

              {/* Attribute */}
              <div className='condition__checkbox'>
                <div className='condition__checkbox--text'>{t(`${PROFILE_MESSAGE}.title_attribute`)}</div>
                <div className='guil__content--checkbox mg__top--0'>
                  {valCheckboxAttribute ? (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(false, 'Attribute')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={ATTRIBUTE_WEATHER} />
                    </div>
                  ) : (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(true, 'Attribute')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={ATTRIBUTE_DISABLE_WEATHER} />
                      <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
                    </div>
                  )}
                </div>
              </div>

              {/* Doping */}
              <div className='condition__checkbox'>
                <div className='condition__checkbox--text'>{t(`${PROFILE_MESSAGE}.title_doping`)}</div>
                <div className='guil__content--checkbox mg__top--0'>
                  {valCheckboxDoping ? (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(false, 'Doping')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={DOPING_WEATHER} />
                    </div>
                  ) : (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(true, 'Doping')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={DOPING_DISABLE_WEATHER} />
                      <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
                    </div>
                  )}
                </div>
              </div>

              {/* Armor */}
              <div className='condition__checkbox'>
                <div className='condition__checkbox--text'>{t(`${PROFILE_MESSAGE}.title_armor`)}</div>
                <div className='guil__content--checkbox mg__top--0'>
                  {valCheckboxArmor ? (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(false, 'Armor')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={ARMOR_WEATHER} />
                    </div>
                  ) : (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(true, 'Armor')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={ARMOR_DISABLE_WEATHER} />
                      <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
                    </div>
                  )}
                </div>
              </div>

              {/* Skin */}
              <div className='condition__checkbox'>
                <div className='condition__checkbox--text'>{t(`${PROFILE_MESSAGE}.title_skin`)}</div>
                <div className='guil__content--checkbox mg__top--0'>
                  {valCheckboxSkin ? (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(false, 'Skin')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={SKIN_WEATHER} />
                    </div>
                  ) : (
                    <div
                      data-value='child'
                      onClick={() => onChangeCheckbox(true, 'Skin')}
                      className='pointer_race_condition'
                    >
                      <MemoizedLoadImageCommon srcImg={SKIN_DISABLE_WEATHER} />
                      <MemoizedLoadImageCommon className="icon_disable_condition" srcImg={DISABLE_RACE_CONDITION_WEATHER} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Button save */}
            <div className='row'>
              <div className='btn-create'>
                <Form.Item>
                  <Button
                    buttonName={
                      <div>
                        {checkEnableBTN ? t(`${PROFILE_MESSAGE}.btn_select_all`) : t(`${PROFILE_MESSAGE}.btn_clear_all`)}
                      </div>
                    }
                    onClickButton={handleSelectAllRaceCondition}
                    width={147}
                    type="btnSelectAll"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    buttonName={
                      <div>
                        {t(`${PROFILE_MESSAGE}.btn_save`)}
                      </div>
                    }
                    onClickButton={handleSaveRaceCondition}
                    width={147}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </>
    )
  }

  return (
    <Modal>
      <ModalRaceCoditionsStyled>
        <div className='user-create_race-modal'>
          <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className={`title-create-race-container line-condition`}>
            <div className={`color-primary`}>
              {t(`${PROFILE_MESSAGE}.input_race_conditions`)}
            </div>
          </div>
          <div className={`create-race-container`}>
            {renderContent()}
          </div>
        </div>
      </ModalRaceCoditionsStyled>
    </Modal>
  )
}

export default ModalRaceCoditions
