import styled from 'styled-components'

const ModalConfirmUserCreateRaceStyled = styled.div`
  width: 902px;
  position: relative;
  background-color: #4df285;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%, 0 calc(100% - 40px));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;

  .user-create_race-modal {
    background-color: ${({ theme }) => theme.color.neutral};
    clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%, 0 calc(100% - 40px));
    padding: 20px 30px;
    position: relative;
    min-height: 300px;
    min-width: 900px;
    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: ${({ theme }) => theme.color.transparent};
      border: none;
      z-index: 1;
    }

    .line-condition {
      border-bottom: 2px solid ${({ theme }) => theme.color.lightGreen};
    }

    .height-85 {
      height: 90px;
    }

    .height-45 {
      height: 45px;
    }

    .title-create-race-container {
      padding-bottom: 10px;
      font-size: 30px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
    }

    .create-race-container {
      display: flex;
      align-items: center;
      .ant-form {
        width: 100%;
      }

      .confirm-container {
        width: 100%;
        margin: 30px 30px 5px;
        font-size: 18px;
      }

      .color-DEDEDE {
        color: #DEDEDE;
      }

      .title-label {
        display: flex;
        align-items: center;
        font-size: 18px;
      }

      .limit-race {
        padding 0 4px;
        color: #4df285;
      }

      .guil__content--checkbox {
        margin-top: 15px;
        line-height: 1.5715;
        position: relative;

        .guild__create--checkbox {
          padding: 0;
          height: initial;
          width: initial;
          margin-bottom: 0;
          display: none;
          cursor: pointer;
        }

        label {
          position: relative;
          cursor: pointer;
          font-size: 18px !important;
        }

        label:before {
          content: "";
          -webkit-appearance: none;
          background-color: transparent;
          border: 2.11952px solid #4EF076;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 8px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 15px;
          background-color: #252B43;
          top: -2px;
        }

        .guild__create--checkbox:checked + label:after {
          content: "";
          display: block;
          position: absolute;
          top: 5px;
          left:6px;
          width: 8px;
          height: 12px;
          border: solid #4EF076;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          background-color: #252B43;
        }
        .border__cover {
          display: block;
          position: absolute;
          top: -4px;
          left:28px;
          width: 8.5px;
          height: 14px;
          border: solid #4EF076;
          border-width: 0 0 2px 0;
          transform: rotate(45deg);
          background-color: #121212;
        }
      }

      .btn-create {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        width: 100%;
        gap: 20px;

        .ant-form-item {
          margin: 0;
        }
      }

      .loading-confirm {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 280px;
      }
    }
  }
`

export default ModalConfirmUserCreateRaceStyled
