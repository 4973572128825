/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react"
import { useTranslation } from "react-i18next";
import { PROFILE_MESSAGE } from "i18n/constants";
import { LINE_BOT } from "assets/images";
import TabContainer from "./styled";

interface iprops {
  onTabClick: (v: string) =>  void
}

const TabsUserCreateRace = ({ onTabClick }: iprops) => {
  const { t } = useTranslation()
  const [tabActive, setTabActive] = useState<any>(t(`${PROFILE_MESSAGE}.tabCurrent`))

  const handleClickTab = (value: string) => () => {
    if (value === tabActive) return
    setTabActive(value)
    onTabClick(value)
  };

  return (
    <TabContainer>
      <div className='tabs-contain'>
        <span
          onClick={handleClickTab(t(`${PROFILE_MESSAGE}.tabCurrent`))}
          className={`color-white title margin-rigth-tab ${tabActive === t(`${PROFILE_MESSAGE}.tabCurrent`) ? 'active' : ''}`}
        >
          {t(`${PROFILE_MESSAGE}.currentRace`)}
          {tabActive === t(`${PROFILE_MESSAGE}.tabCurrent`) ? <img src={LINE_BOT} className='active' alt='' /> : null}
        </span>
        <span
          onClick={handleClickTab(t(`${PROFILE_MESSAGE}.tabHistory`))}
          className={`color-white title ${tabActive === t(`${PROFILE_MESSAGE}.tabHistory`) ? 'active' : ''}`}
        >
          {t(`${PROFILE_MESSAGE}.raceHistory`)}
          {tabActive === t(`${PROFILE_MESSAGE}.tabHistory`) ? <img src={LINE_BOT} className='active' alt='' /> : null}
        </span>
      </div>
    </TabContainer>
  )
}

export default TabsUserCreateRace
