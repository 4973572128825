import { BG_INPUT_RACE } from 'assets/images'
import styled from 'styled-components'

interface InputStyledProps {
  width: number | undefined
  heigth: number | undefined
}

const InputStyled = styled.div<InputStyledProps>`
  width: ${({ width }) => (width ? width + 'px' : '100%')};
  height: ${({ heigth }) => (heigth + 'px')};
  padding: 2px 16px 2px 4px;
  column-gap: 8px;

  /* background-color: ${({ theme }) => theme.color.neutralBackground};
  clip-path: polygon(97% 0%, 100% 15px, 100% 100%, 0 100%, 0 0); */
  background: url(${BG_INPUT_RACE});
  background-repeat: no-repeat;
  background-size: 100% 100%;

  ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
    width: 100%;
    clip-path: none;
  }

  .orange-line {
    left: 0;
    bottom: -1px;
    width: 70px;
    height: 3px;
  }

  .search-icon {
    width: 20px;
  }

  .search-input {
    background-color: ${({ theme }) => theme.color.transparent} !important;
    border: none;
    resize: none;

    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.white};

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.color.grey};
    }
  }

  .input_opacity {
    opacity: 0.3;
  }

  .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
    box-shadow: 0 0 0 2px transparent;
  }

  .ant-input, .ant-input-password {
    background-color: transparent;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    transition: color 5000s ease-in-out, background-color 5000s ease-in-out;
  }

  .ant-input,
  .ant-input-password input {
    background-color: transparent !important;
    color: #fff !important;
    box-shadow: none !important;
    transition: color 5000s ease-in-out, background-color 5000s ease-in-out;
  }

  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border-color: transparent;
  }

  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus {
    border-color: transparent;
  }

  input:-internal-autofill-selected {
    background-color: transparent !important;
    color: #fff !important;
    transition: color 5000s ease-in-out, background-color 5000s ease-in-out;
  }

  .ant-input::placeholder {
    color: rgb(157, 157, 157);
  }
`

export default InputStyled
