/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

interface Props {
  width?: number
  height?: number
  marginRigth?: number
  margin?: number
}

const ImageRaceConditionsContainer = styled.div<Props>`
  .contain__img_race_condition {
    display: flex;

    .child_contain__img_race_condition {
      display: flex;
      margin: 2px 0;
    }

    /* .no_margin_top {
      margin-top: 0;
    } */

    .img_race_condition {
      width: ${p => `${p.width}px`};
      height: ${p => `${p.height}px`};
      margin: ${p => `0 ${p.margin}px`};
      margin-right: ${p => p.margin ? `3px` : `${p.marginRigth}px`};
    }

    .mg__left_5 {
      margin-left: 5px;
    }

    .pointer_race_condition {
      cursor: pointer;
      position: relative;
    }

    .icon_disable_condition {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
    }
  }
  .flex_direction_colunm {
    flex-direction: column;
  }
`
export default ImageRaceConditionsContainer