import styled from 'styled-components'

// interface UserCreateRaceStyledProp {
//   total: number
// }

const UserCreateRaceStyled = styled.div`
  background-color: transparent;
  text-align: center;
  .disable {
    display: none;
  }
  .head-create-race-container {
    width: 750px;
    margin: auto;
    .head-create-race {
      margin-top: 34px;
      .title {
        text-decoration: none;
        color: #4bf296;
        font-size: 32px;
        line-height: 26px;
        text-transform: uppercase;
      }
    }
    .button-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0;

      span {
        text-transform: uppercase;
        font-size: 18px;
      }
    }
  }

  .box-tabs-current-race {
    display: flex;
    margin-top: 16px;
    width: 1000px;
    justify-content: space-between;
  }
`

export default UserCreateRaceStyled
